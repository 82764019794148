import { memo } from "react";
import { useTranslation } from "../../../../hooks";
import { T } from "../../../../constants";

interface HeaderProps {
  handleFairnessPopUpClick: () => void;
  betCount: number;
}

const Header: React.FC<HeaderProps> = ({
  handleFairnessPopUpClick,
  betCount,
}) => {
  const t = useTranslation();

  return (
    <div className="qrBetsHeader">
      <div className="qrBetsHeaderTxtContainer">
        <span className="qrBetsHeaderTxt">{t(T.MY_BETS)}</span>
        <div className="qrBetCountContainer">
          <span className="qrBetCount">{betCount}</span>
        </div>
      </div>
      <button
        type="button"
        className="qrBetsHeaderBtnContainer"
        onClick={handleFairnessPopUpClick}
      >
        <i className="icon-fairnessIcon qrBetsHeaderBtnIcon big" />
      </button>
    </div>
  );
};

export default memo(Header);
