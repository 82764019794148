import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { CheckTicketResult } from "./check-ticket-result";
import { CheckTicketForm } from "./check-ticket-form";
import { useQuery, useStore } from "../../../../hooks";
import { DEFAULT_TAKE, T } from "../../../../constants";
import { Loader, Modal } from "../../shared";
import { Toaster } from "../../../containers";

interface CheckTicketModalProps {}

export const CheckTicketModal: React.FC<CheckTicketModalProps> = observer(
  () => {
    const { query } = useQuery();
    const skip = +query.skip || 0;
    const take = +query.take || DEFAULT_TAKE;

    const {
      ticketsStore,
      modalStore: {
        setCheckModal,
        checkModal: { data },
      },
      partnerStore: { isPinRequiredForPayout, maxPayout },
    } = useStore();

    const {
      payTicketResponse: { isLoading: payLoading },
      ticket: { data: ticket, isLoading: ticketLoading },
      setBarrCodeErrorMessage,
      barrCodeErrorMessage,
    } = ticketsStore;

    const [inactiveCancelBtn, setInactiveCancelBtn] = useState(false);
    const [barCode, setBarCode] = useState(data || "");

    useEffect(() => {
      return () => {
        resetTicketData();
      };
    }, []);

    const resetTicketData = useCallback(() => {
      ticketsStore.resetTicketData();
    }, []);

    const handleClose = useCallback(() => {
      setCheckModal({ open: false, data: "" });
    }, []);

    const handleBarcodeChange = useCallback((barCode: string) => {
      setCheckModal({ open: true, data: barCode });
      setBarCode(barCode);
    }, []);

    const handleCheckClick = useCallback(() => {
      const barCodeOrTicketId = +barCode || ticket?.ticketId;

      if (barCodeOrTicketId) {
        ticketsStore.getTicket(+barCodeOrTicketId);
      }
    }, [barCode]);

    const handleLastTicketClick = useCallback(() => {
      ticketsStore.getLastTicket();
    }, []);

    const handleCheckAgainClick = useCallback(() => {
      resetTicketData();
    }, [resetTicketData]);

    const handlePayClick = useCallback(() => {
      const barcode = +barCode || ticket?.barcode;
      if (barcode && ticket) {
        ticketsStore.payTicket(+barcode, ticket.ticketId);
      }
    }, [barCode, ticket]);

    const handleCancelTicket = useCallback(() => {
      if (!barCode) return;
      setInactiveCancelBtn(true);
      ticketsStore
        .cancelTicket(+barCode)
        .then((r) => {
          getAllTickets();
          handleClose();
        })
        .catch((error) => {
          Toaster.error({ message: error?.errorMessage });
        })
        .finally(() => {
          setInactiveCancelBtn(false);
        });
    }, [barCode]);

    const getAllTickets = useCallback(() => {
      ticketsStore.getTickets({
        ...query,
        skip,
        take,
      });
    }, [skip, take]);

    if (ticketLoading) return <Loader />;

    return (
      <Modal title={T.CHECK} onClose={handleClose}>
        {!ticket ? (
          <CheckTicketForm
            barcode={barCode}
            loading={ticketLoading}
            onCheckClick={handleCheckClick}
            errorMessage={barrCodeErrorMessage}
            onBarcodeChange={handleBarcodeChange}
            onLastTicketClick={handleLastTicketClick}
            setBarrCodeErrorMessage={setBarrCodeErrorMessage}
            isPinRequiredForPayout={isPinRequiredForPayout}
          />
        ) : (
          <CheckTicketResult
            barcode={barCode}
            ticket={ticket}
            maxPayout={maxPayout}
            setBarCode={setBarCode}
            payLoading={payLoading}
            onPayClick={handlePayClick}
            cancelTicket={handleCancelTicket}
            onCheckAgainClick={handleCheckAgainClick}
            inactiveCancelBtn={inactiveCancelBtn}
            isPinRequiredForPayout={isPinRequiredForPayout}
          />
        )}
      </Modal>
    );
  },
);
