import React, { useCallback } from "react";

import { useTranslation } from "../../../../hooks";
import { IconTypes } from "../../../../enums";
import { Icon } from "../icon";

interface ModalHeaderProps {
  title: string;
  onClose: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose }) => {
  const t = useTranslation();

  const onCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <div className="popup-title">
      {/*<div className="popup-print-icon-holder">*/}
      {/*    <i className="icon-print popup-print-icon"></i>*/}
      {/*</div>*/}
      <p className="title-text">{t(title)}</p>
      <div className="close-block-container" onClick={onCloseClick}>
        <Icon type={IconTypes.Close} className="close-block" />
      </div>
    </div>
  );
};
