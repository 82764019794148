import moment, { MomentInput } from "moment";

import { DATE_FORMAT, DATE_FORMAT_BACK } from "../constants";

export const utcToLocal = (date: MomentInput, format = DATE_FORMAT) => {
  return moment.utc(date).local().format(format);
};

export const dateToUtc = (date: Date, format = DATE_FORMAT_BACK) => {
  return moment(date).utc().format(format);
};

export const dateToUtcForHours = (date: Date, format = DATE_FORMAT_BACK) => {
  return moment(date).format(format);
};

export const formatDate = (date: Date | string, format = DATE_FORMAT) => {
  return moment(date).format(format);
};

export const getNewDate = () => {
  let date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  return date;
};

export const convertTimeUtc = (
  time: moment.MomentInput,
  format = DATE_FORMAT_BACK,
) => {
  const selectedMoment = moment(time, format);
  const utcTime = selectedMoment.utc();
  return utcTime.format(format);
};
