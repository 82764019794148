import React, { useState } from "react";

import { useTranslation } from "../../../../../../../hooks";
import { IconTypes } from "../../../../../../../enums";
import { Currency, Icon } from "../../../../../shared";

interface WalletFieldProps {
  label: string;
  amount: number;
  icon: IconTypes;
  isBalance?: boolean;
  showBalanceLimit?: any;
  withMoneyIcons?: boolean;
  setShowBalanceLimit?: any;
}

export const WalletField: React.FC<WalletFieldProps> = ({
  icon,
  label,
  amount,
  isBalance,
  showBalanceLimit,
  withMoneyIcons,
  setShowBalanceLimit,
}) => {
  const t = useTranslation();

  const handleChangeBalanceLimitVisibleFunctionality = () => {
    setShowBalanceLimit(
      isBalance
        ? {
            ...showBalanceLimit,
            isShowBalance: !showBalanceLimit.isShowBalance,
          }
        : {
            ...showBalanceLimit,
            isShowLimit: !showBalanceLimit.isShowLimit,
          },
    );
  };

  return (
    <div className="balance-limit-column">
      <div className="balance-limit-icon-container">
        <Icon type={icon} />
      </div>
      <div
        className="balance-limit-text-container"
        onClick={handleChangeBalanceLimitVisibleFunctionality}
      >
        <p className="balance-limit-text">
          <span className="balance-limit-text-inner">{t(label)}</span>
          {(
            withMoneyIcons && isBalance
              ? showBalanceLimit.isShowBalance
              : showBalanceLimit.isShowLimit
          ) ? (
            <span className="icon-eye eye-icon" />
          ) : (
            <span className="icon-eye-off eye-icon" />
          )}
        </p>
        <p className="balance-limit-value">
          <Currency
            withMoneyIcons={withMoneyIcons}
            isBalance={isBalance}
            value={amount}
            showBalanceLimit={showBalanceLimit}
          />
        </p>
      </div>
    </div>
  );
};
