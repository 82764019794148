import { makeObservable, observable, action } from "mobx";

import { PostMessageTypes, Theme } from "../enums";
import { iframeService } from "../services";
import { RootStore } from ".";

export class IFrameStore {
  private rootStore?: RootStore;

  public frame: HTMLIFrameElement | null = null;

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      frame: observable,
      setFrame: action.bound,
    });
  }

  public setFrame(value: HTMLIFrameElement | null): void {
    this.frame = value;
  }

  public notifyThemeChange(theme: Theme): void {
    if (!this.frame) return;
    const message = { type: PostMessageTypes.ThemeChange, payload: theme };
    iframeService.sendMessageToFrame(this.frame, message);
  }
}
