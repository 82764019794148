import React from "react";
import { useTranslation } from "../../../../hooks";

interface DropdownListItemProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

export const DropdownListItem: React.FC<DropdownListItemProps> = ({
  label,
  onClick,
  isActive,
}) => {
  const t = useTranslation();

  return (
    <div
      className={`scroll-tabs-top-info ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      <p className="scroll-tabs-top-nav-text">{t(label)}</p>
      <span className="for-icon-don icon-done" />
    </div>
  );
};
