import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import { useOutsideClick } from "../../../../../../../hooks";
import { LanguageModel } from "../../../../../../../models";
import { LanguageListItem } from "../language-list-item";

interface LanguageListProps {
  selected: string;
  onOutsideClick: () => void;
  languages: Array<LanguageModel>;
  onSelect: (code: string) => void;
  openerRef: MutableRefObject<HTMLDivElement | null>;
}

export const LanguageList: React.FC<LanguageListProps> = ({
  selected,
  onSelect,
  languages,
  openerRef,
  onOutsideClick,
}) => {
  const [filteredFlags, setFilteredFlags] = useState<Array<LanguageModel>>([]);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(onOutsideClick, popoverRef, openerRef);

  useEffect(() => {
    setFilteredFlags(languages.filter((lan) => lan.uid !== selected));
  }, [languages, selected]);

  return (
    <div className="drop-cont-wrp" ref={popoverRef}>
      <div className="select-country-drop-container">
        <div className="scroll-holder">
          <div className="scrollable-container static-scroll">
            {filteredFlags.map(({ code, flag, uid }) => (
              <LanguageListItem
                uid={uid}
                key={code}
                flag={flag}
                label={code}
                onSelect={onSelect}
                selected={selected === code}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
