import axios, { AxiosError, AxiosResponse } from "axios";

import { combineQuery } from "../helpers";
import { DEFAULT_ERROR_RESPONSE } from "../constants";
import { HTTPResponse, QueryModel } from "./../models";
import { httpErrorHandlerService } from "./http-error-handler-service";
import { checkResponseStatusAndLogOut } from "../api/checkResponseStatusAndLogOut";

export const http = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
  },
  paramsSerializer: {
    serialize: (params: QueryModel) => {
      return combineQuery(params).slice(1);
    },
  },
});

const onFulfilled = (res: AxiosResponse<HTTPResponse<any>>) => res.data.result;

const onRejected = (error: AxiosError) => {
  if (error?.response?.status === 401) {
    checkResponseStatusAndLogOut();
  }
  httpErrorHandlerService.handle(error);
  console.log(error);
  throw error.response?.data || DEFAULT_ERROR_RESPONSE;
  return error.response?.data || DEFAULT_ERROR_RESPONSE;
};

http.interceptors.response.use(onFulfilled, onRejected);
