import * as CryptoJS from "crypto-js";

export const sha512WithKeyAndSalt = async (
  key: string,
  salt: string,
): Promise<string> => {
  const combinedInput = key + salt;

  return CryptoJS.SHA512(combinedInput)
    .toString(CryptoJS.enc.Hex)
    .toUpperCase();
};
