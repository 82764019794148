import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { RoutePaths } from "../../../constants";
import { useStore } from "../../../hooks";
import QRTicketDetails from "../../../view/pages/QR-ticket-details";

interface IsQRTicketLayerProps {}

export const IsQRTicketLayer: React.FC<IsQRTicketLayerProps> = observer(() => {
  const {
    authStore: { isAuth },
  } = useStore();

  return isAuth ? <Navigate to={RoutePaths.Game} /> : <QRTicketDetails />;
});
