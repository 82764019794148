import React, { forwardRef } from "react";

import { IconTypes } from "../../../../../../../enums";
import { Icon } from "../../../../../shared";

interface UsernameProps {
  username: string;
  onClick: () => void;
}

export const Username = forwardRef<null | HTMLDivElement, UsernameProps>(
  ({ onClick, username }, ref) => {
    return (
      <div ref={ref} onClick={onClick} className="username-wr">
        <div className="username-icon-container">
          <Icon type={IconTypes.User} />
        </div>
        <p className="username-text">{username}</p>
        <div className="user-arrow-icon-container">
          <Icon type={IconTypes.Arrow} />
        </div>
      </div>
    );
  },
);
