import { memo } from "react";

interface KenoProps {
  result: string;
}
const Keno: React.FC<KenoProps> = ({ result }) => {
  const numbers = JSON.parse(result);

  return (
    <div className="qrResultGameAboutTxtKeno ">
      {numbers.map((item: number) => (
        <div className="gameResultsNumberContainer">
          <span className="gameResultsNumbersTxt">{item}</span>
        </div>
      ))}
    </div>
  );
};

export default memo(Keno);
