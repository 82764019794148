import { BaseApiService } from "../../base-api-service";
import { LanguageModel } from "../../../models";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";

class LanguagesController extends BaseApiService {
  public async getLanguages(): Promise<Array<LanguageModel>> {
    const url = `${this.url}/api/${ApiVersions.V1}/languages/${env.applicationId}`;
    return await this.http.get(url);
  }
}

export const languageController = new LanguagesController({ url: env.httpUrl });
