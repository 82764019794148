import { RootStore } from "./index";
import { action, makeObservable, observable } from "mobx";

import { languageController } from "../api/controllers/languages";
import { LanguageModel } from "../models";
import { HttpResponse } from "../api";

export class LanguageStore {
  private rootStore?: RootStore;

  public languages = new HttpResponse<Array<LanguageModel>>([]);

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      languages: observable,
      setLanguages: action.bound,
    });
  }

  public setLanguages(value: HttpResponse<Array<LanguageModel>>): void {
    this.languages = value;
  }

  public async getLanguages(): Promise<string> {
    this.setLanguages(this.languages.fetching());
    const result = await languageController.getLanguages();

    const selectedLanguageCode =
      result.find((lan: LanguageModel) => lan.isDefault)?.uid || "en";
    if (!this.rootStore?.translationsStore.selectedLanguageCode) {
      this.rootStore?.translationsStore.setSelectedLanguageCode(
        selectedLanguageCode,
      );
    }
    this.setLanguages(this.languages.fetched(result));
    return selectedLanguageCode;
  }
}
