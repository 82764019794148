import React, { useCallback } from "react";

import { IconTypes, NotificationTypes } from "../../../../enums";
import { useTranslation } from "../../../../hooks";
import { Notification } from "../../../../models";

interface NotificationItemProps extends Notification {
  onClose: (id: string) => void;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  id,
  icon,
  type,
  message,
  onClose,
}) => {
  const t = useTranslation();

  const handleCloseClick = useCallback(() => {
    onClose(id);
  }, [onClose, id]);

  return (
    <li
      className={`notification ${type === NotificationTypes.Error ? "error" : ""}`}
    >
      <i className={icon} />
      <p>{t(message)}</p>
      <button
        type="button"
        onClick={handleCloseClick}
        className={IconTypes.Close}
      />
    </li>
  );
};
