import { Empty } from '../view/components/shared';
import { defineGuards } from './define-guards';
import { lazyWithRetry } from '../helpers';
import { RoutePaths } from '../constants';
import { RouteModel } from '../models';
import { IsAuthLayer } from './layers';

const Bets = lazyWithRetry(() => import('../view/pages/bets'));
const Transactions = lazyWithRetry( () => import('../view/pages/transactions'))
const MainRedirect = lazyWithRetry(() => import('./main-redirect'));
const NotFound = lazyWithRetry(() => import('../view/pages/not-found'));

export const MainRoutes: Array<RouteModel> = [
  {
    path: RoutePaths.Empty,
    component: MainRedirect,
  },
  {
    path: RoutePaths.Game,
    component: () => defineGuards([IsAuthLayer], Empty)
  },
  {
    path: RoutePaths.Bets,
    component: () => defineGuards([IsAuthLayer], Bets)
  },
  {
    path: RoutePaths.Transactions,
    component: () => defineGuards([IsAuthLayer], Transactions)
  },
  {
    path: RoutePaths.WildCard,
    component: NotFound
  }
];
