import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { observer } from "mobx-react-lite";

import { getCurrentLanguageCode, getCurrentTheme } from "../../../helpers";
import { iframeService, scanService } from "../../../services";
import { Loader } from "../../components/shared";
import { useQuery, useStore } from "../../../hooks";

interface SplashProps {
  children: React.ReactNode;
}

export const Splash: React.FC<SplashProps> = observer(({ children }) => {
  const { authStore, translationsStore } = useStore();
  const { isAuth } = authStore;
  const { query } = useQuery();

  const [translationsOrRefreshLoading, setTranslationsOrRefreshLoading] =
    useState(true);

  useLayoutEffect(() => {
    setTheme();
  }, []);

  useEffect(() => {
    getTranslations();
    iframeService.listenOnMessage();
    scanService.listenScanEvent();
  }, []);

  const getTranslations = useCallback(async () => {
    try {
      if (isAuth) {
        await authStore.refresh();
      } else {
        await translationsStore.getTranslations({
          code: query.lang || getCurrentLanguageCode() || "en",
        });
      }
      setTranslationsOrRefreshLoading(false);
    } catch (e) {
      await authStore.logout();
    }
  }, [isAuth]);

  const setTheme = useCallback(() => {
    document.documentElement.setAttribute("class", getCurrentTheme());
  }, []);

  return <>{translationsOrRefreshLoading ? <Loader /> : children}</>;
});
