import React, { ChangeEvent, InputHTMLAttributes, useCallback } from "react";
import { useTranslation } from "../../../../hooks";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  regEx?: RegExp;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Input: React.FC<InputProps> = ({
  regEx,
  onChange,
  value = "",
  type = "text",
  className = "",
  placeholder = "",
  ...restProps
}) => {
  const t = useTranslation();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isValid = regEx ? regEx.test(event.target.value) : true;
      if (!isValid || !onChange) return;
      onChange(event);
    },
    [onChange, regEx],
  );

  return (
    <input
      type={type}
      value={value}
      className={className}
      onChange={handleChange}
      placeholder={t(placeholder)}
      {...restProps}
    />
  );
};
