import React, { forwardRef } from "react";

import { IconTypes } from "../../../../enums";
import { Icon } from "../icon";

interface DropdownValueProps {
  label: string;
  placeholder?: string;
  onClick?: () => void;
}

export const DropdownValue = forwardRef<
  null | HTMLDivElement,
  DropdownValueProps
>(({ label, onClick, placeholder }, ref) => {
  return (
    <div className="top-filter-tabs-p" onClick={onClick} ref={ref}>
      <p className="tabs-text">{label || placeholder}</p>
      <Icon type={IconTypes.Arrow} className="tabs-icons" />
    </div>
  );
});
