import { WEEK_DAYS } from '../../../../../constants';

interface WeeksProps {

}

export const Weeks: React.FC<WeeksProps> = () => {
  return (
    <div className="days-items-block">
      {
        WEEK_DAYS.map((week) => (
          <div
            key={week}
            className="days-items"
          >
            { week }
          </div>
        ))
      }
    </div>
  );
};
