import { KeyValuePair, TableColumn } from "../models";
import { formatNumber, utcToLocal } from "../helpers";
import { useTranslation } from "./use-translation";
import { TABLE_DATE_FORMAT } from "../constants";
import { TableColumnType } from "../enums";
import React, { useMemo } from "react";

const formatters: KeyValuePair<(value: any) => React.ReactNode> = {
  [TableColumnType.Number]: formatNumber,
  [TableColumnType.Text]: (value: string) => value ?? "-",
  [TableColumnType.Date]: (value: Date) =>
    value ? utcToLocal(value, TABLE_DATE_FORMAT) : "-",
};

export const useTableColumns = (columns: Array<TableColumn>) => {
  const t = useTranslation();

  const cols = useMemo((): Array<TableColumn> => {
    return columns.map(({ dataKey, type, title, ...rest }) => {
      let formatter = (value: any) => value;
      if (type && formatters[type]) {
        formatter = formatters[type];
      }

      return {
        dataKey: dataKey,
        title: t(title),
        formatter,
        ...rest,
      };
    });
  }, [columns, t]);

  return {
    cols,
  };
};
