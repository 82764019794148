import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { IconTypes } from "../../../../../../enums";
import { useStore } from "../../../../../../hooks";
import { T } from "../../../../../../constants";
import { WalletField } from "./wallet-field";
import { IS_UNIVERSAL_PARTNER } from "../../../../../../constants/host-name";

interface WalletProps {}

export const Wallet: React.FC<WalletProps> = observer(() => {
  const {
    userStore: {
      wallet: {
        data: { balance, limit },
      },
    },
  } = useStore();
  const {
    modalStore: { setIsOpenBalanceAndLimitModal },
    providersStore,
  } = useStore();
  const [showBalanceLimit, setShowBalanceLimit] = useState({
    isShowBalance: false,
    isShowLimit: false,
  });
  const {
    providers: {
      data: { partnerId },
    },
  } = providersStore;

  return (
    <>
      <button
        type="button"
        className="switch-wallet icon-switch"
        onClick={() => setIsOpenBalanceAndLimitModal(true)}
      />
      <div className="header-balance-limit-container">
        <WalletField
          amount={balance}
          label={IS_UNIVERSAL_PARTNER ? T.LIMIT : T.BALANCE} // TODO
          icon={IconTypes.Balance}
          withMoneyIcons
          showBalanceLimit={showBalanceLimit}
          isBalance
          setShowBalanceLimit={setShowBalanceLimit}
        />
        <WalletField
          amount={limit}
          label={IS_UNIVERSAL_PARTNER ? T.BALANCE : T.LIMIT}
          withMoneyIcons
          icon={IconTypes.Warning}
          showBalanceLimit={showBalanceLimit}
          setShowBalanceLimit={setShowBalanceLimit}
        />
      </div>
    </>
  );
});
