import { KeyValue } from "../key-value";
import { Line } from "../line";
import { T } from "../../../../../../constants";
import { TicketConfig } from "../../../../../../models/partnerResponse";
import { toFixNumber } from "../../../../../../helpers/to-fix-number";

interface MarketProps {
  odd: number;
  gameName: string;
  betAmount: number;
  showLine?: boolean;
  marketName: string;
  winAmount?: number;
  isExpress: boolean;
  currencyCode: string;
  showWinAmount?: boolean;
  possibleWin?: number;
  handicap?: number;
  roundId: number;
  selectionName?: string;
  eventName?: string;
  ticketConfig?: TicketConfig;
}

export const Market: React.FC<MarketProps> = ({
  odd,
  gameName,
  betAmount,
  winAmount,
  isExpress,
  marketName,
  currencyCode,
  showLine = true,
  showWinAmount = false,
  possibleWin,
  selectionName,
  handicap,
  roundId,
  eventName,
  ticketConfig,
}) => {
  return (
    <>
      <KeyValue label={T.GAME} value={gameName} textBold />

      {eventName ? <KeyValue label={eventName} value="" /> : null}

      <KeyValue label={T.ROUND_ID} value={roundId} />

      {ticketConfig?.showMarketNames ? (
        <KeyValue label={`${marketName}`} value={`x${odd}`} />
      ) : null}

      {selectionName ? (
        <KeyValue label={selectionName} value={handicap || ""} />
      ) : null}

      {!isExpress && (
        <KeyValue
          label={T.BET_AMOUNT}
          value={`${toFixNumber(betAmount)} ${currencyCode}`}
        />
      )}

      {possibleWin && ticketConfig?.showExpectedWinPerBet && !winAmount ? (
        <KeyValue
          label={T.POSSIBLE_WIN}
          value={`${toFixNumber(possibleWin)} ${currencyCode}`}
        />
      ) : null}

      {showWinAmount && !isExpress && winAmount ? (
        <KeyValue
          label={T.WINNING_AMOUNT}
          value={`${winAmount} ${currencyCode}`}
        />
      ) : null}

      {showLine && <Line type="dashed" />}
    </>
  );
};
