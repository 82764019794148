import React from "react";

import { IconTypes } from "../../../../../../../enums";
import { env } from "../../../../../../../environment";
import { Icon } from "../../../../../shared";

interface LanguageListItemProps {
  uid: string;
  label: string;
  selected: boolean;
  flag: string | null;
  onSelect: (code: string) => void;
}

export const LanguageListItem: React.FC<LanguageListItemProps> = ({
  uid,
  flag,
  label,
  onSelect,
  selected,
}) => {
  return (
    <div
      onClick={() => onSelect(uid)}
      className={`drop-country-row ${selected ? "selected" : ""}`}
    >
      <div className="country-flag-container">
        {flag && (
          <img src={`${env.httpUrl}/translationService/${flag}`} alt={label} />
        )}
      </div>
      <span className="country-name-text">{label}</span>
    </div>
  );
};
