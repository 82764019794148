import React from "react";
import { T } from "../../../../../constants";
import { useTranslation } from "../../../../../hooks";

interface SuccessPopUpProps {}

const SuccessPopUp: React.FC<SuccessPopUpProps> = () => {
  const t = useTranslation();

  return (
    <div className="operationsMessage success">
      <i className="operationsMessage-icon icon-done" />
      <p className="operationsMessageText">{t(T.SUCCESS)}</p>
    </div>
  );
};

export default SuccessPopUp;
