import { memo } from "react";
import { useTranslation } from "../../../../hooks";
import { T } from "../../../../constants";

interface FooterProps {
  possibleWin: number | boolean;
  currencyCode: string;
  totalOdd: string | null;
  totalBetAmount: string;
  totalWinAmount: string | boolean;
}

const Footer: React.FC<FooterProps> = ({
  currencyCode,
  possibleWin,
  totalBetAmount,
  totalOdd,
  totalWinAmount,
}) => {
  const t = useTranslation();

  return (
    <div className="wrapperFooter">
      {totalOdd ? (
        <div className="wrapperFooterContainer">
          <span className="wrapperFooterTxt">{t(T.TOTAL_ODDS)}</span>
          <span className="wrapperFooterBalance"> x{totalOdd}</span>
        </div>
      ) : null}

      <div className="wrapperFooterContainer">
        <span className="wrapperFooterTxt">{t(T.TOTAL_BET)}</span>
        <span className="wrapperFooterBalance">{`${totalBetAmount} ${currencyCode}`}</span>
      </div>
      {possibleWin !== false ? (
        <div className="wrapperFooterContainer">
          <span className="wrapperFooterTxt">{t(T.POSSIBLE_WIN)}</span>
          <span className="wrapperFooterBalance">{`${possibleWin} ${currencyCode}`}</span>
        </div>
      ) : null}

      {totalWinAmount !== false ? (
        <div className="wrapperFooterContainer">
          <span className="wrapperFooterTxt">{t(T.TOTAL_WIN_AMOUNT)}</span>
          <span className="wrapperFooterBalance">{`${totalWinAmount} ${currencyCode}`}</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(Footer);
