import React, { forwardRef } from "react";

import { useTranslation } from "../../../../../hooks";
import { IconTypes } from "../../../../../enums";
import { Icon } from "../../icon";

interface DatePickerInputProps {
  value?: string;
  onClick: () => void;
  placeholder?: string;
}

export const DatePickerInput = forwardRef<
  null | HTMLDivElement,
  DatePickerInputProps
>(({ onClick, value = "", placeholder = "" }, ref) => {
  const t = useTranslation();

  return (
    <div ref={ref} onClick={onClick} className="date-picker-inputs-wr">
      <Icon className="date-box" type={IconTypes.Date} />
      <input
        readOnly
        type="text"
        value={value}
        placeholder={t(placeholder)}
        className="filter-date-input"
      />
    </div>
  );
});
