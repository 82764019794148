import React, { useCallback, useMemo } from "react";

import { PaginationArrow } from "./pagination-arrow";
import { IconTypes } from "../../../../../enums";
import { guid } from "../../../../../helpers";
import { Page } from "./page";

interface PaginationProps {
  count: number;
  currentPage: number;
  selectorValue: number;
  onPaginationChange: (page: number, selectorValue: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  count,
  currentPage,
  selectorValue,
  onPaginationChange,
}) => {
  const arrowLeftDisabled = currentPage === 1;
  const arrowRightDisabled = currentPage === count;

  const arrowLeftClick = useCallback(() => {
    if (!arrowLeftDisabled) {
      return onPaginationChange(currentPage - 1, selectorValue);
    }
  }, [selectorValue, currentPage, arrowLeftDisabled, onPaginationChange]);

  const arrowRightClick = useCallback(() => {
    if (!arrowRightDisabled) {
      return onPaginationChange(currentPage + 1, selectorValue);
    }
  }, [selectorValue, currentPage, arrowRightDisabled, onPaginationChange]);

  const pages = useMemo(() => {
    return new Array(count).fill(null).map((_, i) => i + 1);
  }, [count]);

  const visiblePages = useMemo(() => {
    // second visible page from left (depends on {selectedPage})
    const secondVisiblePage =
      currentPage >= 5
        ? count - currentPage > 2
          ? currentPage - 2
          : count - 5
        : 2;
    return pages
      .map((page) => {
        // visible: first page, last page, [{secondVisiblePage}, {secondVisiblePage} + 5) range pages
        const visible =
          page === 1 ||
          page === count ||
          (page >= secondVisiblePage && page < secondVisiblePage + 5);
        return {
          visible,
          number: page,
        };
      })
      .filter(({ visible }) => visible)
      .map((page, i) => ({
        ...page,
        id: guid(),
        // number is 0: if second page is not 2 and second last page is not {count} - 1, else number is same
        number:
          (i === 1 && page.number !== 2) ||
          (i === 5 && page.number !== count - 1 && i !== count - 1)
            ? 0
            : page.number,
      }));
  }, [pages, currentPage, count]);

  return (
    <div className="pagination-right">
      <PaginationArrow
        onClick={arrowLeftClick}
        icon={IconTypes.ArrowLeft}
        disabled={arrowLeftDisabled}
      />
      {visiblePages.map((page) => (
        <Page
          key={page.id}
          page={page.number || "..."}
          onClick={() =>
            page.number ? onPaginationChange(page.number, selectorValue) : null
          }
          className={`page-btn ${currentPage === page.number ? "active" : ""} ${page.number === 0 ? "disabled" : ""}`}
        />
      ))}
      <PaginationArrow
        onClick={arrowRightClick}
        icon={IconTypes.ArrowRight}
        disabled={arrowRightDisabled}
      />
    </div>
  );
};
