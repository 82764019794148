import onScan from "onscan.js";

import { rootStore } from "../store";

class ScanService {
  public listenScanEvent(): void {
    // Enable scan events for the entire document
    if (onScan.isAttachedTo(document)) return;
    onScan.attachTo(document, {
      onScan: this.handleScan.bind(this),
      // @ts-ignore // the library accepts only boolean but in functional works for strings
      ignoreIfFocusOn: "input",
    });
  }

  public handleScan(scanCode: string): void {
    // const barcode = this.decode(scanCode);
    const { modalStore, ticketsStore } = rootStore;
    modalStore.setCheckModal({ open: true, data: scanCode });
    ticketsStore.resetTicketData();
    ticketsStore.getTicket(+scanCode);
  }

  private decode(scanCode: string): string {
    let text = "";
    for (let i = 0; i < scanCode.length; i = i + 4) {
      text += String.fromCharCode(+scanCode.slice(i, i + 4));
    }
    return text;
  }
}

export const scanService = new ScanService();
