export const RoutePaths = {
  Empty: '/',
  Transactions: '/Transactions',
  Login: '/login',
  Game: '/game',
  Bets: '/bets',
  Providers: '/providers',
  QRTicketDetails: '/ticketDetails',
  WildCard: '/*'
};
