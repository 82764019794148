import { makeObservable, observable, action } from "mobx";
import { RootStore } from ".";
import { managerController } from "../api";
import { AxiosResponse } from "axios";

export class ManagerStore {
  // private rootStore?: RootStore;

  public errorMessageForManagerBalance: null | string = "";

  isShowSuccessesPart: boolean = false;

  constructor(rootStore?: RootStore) {
    // this.rootStore = rootStore;
    makeObservable(this, {
      errorMessageForManagerBalance: observable,
      isShowSuccessesPart: observable,
      setTransferData: action.bound,
      setTakeBalance: action.bound,
      setIsShowSuccessesPart: action.bound,
      setErrorMessageForManagerBalance: action.bound,
    });
  }

  setIsShowSuccessesPart(bool: boolean) {
    this.isShowSuccessesPart = bool;
  }

  public setErrorMessageForManagerBalance(arg: null | string) {
    this.errorMessageForManagerBalance = arg;
  }

  public async setTransferData(
    amount: number | string,
    pinCode: number | string,
  ): Promise<any> {
    try {
      const response = await managerController.transferBalance(amount, pinCode);
      this.isShowSuccessesPart = true;
      return response;
    } catch (err: any) {
      this.errorMessageForManagerBalance = err.errorMessage;
    }
  }

  public async setTakeBalance(
    amount: number | string,
    pinCode: number | string,
  ): Promise<any> {
    try {
      const response = await managerController.takeBalance(amount, pinCode);
      this.isShowSuccessesPart = true;
      return response;
    } catch (err: any) {
      this.errorMessageForManagerBalance = err.errorMessage;
    }
  }
}
