import {
  Ticket,
  TicketsResponse,
  GetTicketsRequest,
  TicketOverviewResponse,
  GetTicketOverviewRequest,
  TicketDetails,
  TicketsDetailsResponse,
} from "../../../models";
import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";
import { ManagerResponse } from "../../../models/managerResponse";

class TicketsController extends BaseApiService {
  public async getTickets(
    params?: GetTicketsRequest,
  ): Promise<TicketsResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets`;
    return await this.http.get(url, { params });
  }

  public async getTicketByBarcode(barCodeOrTicketId: number): Promise<Ticket> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets/${barCodeOrTicketId}`;
    return await this.http.get(url);
  }

  public async getLastTicket(): Promise<Ticket> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets/last`;
    return await this.http.get(url);
  }

  public async getTicketOverview(
    params?: GetTicketOverviewRequest,
  ): Promise<TicketOverviewResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets/overview`;
    return await this.http.get(url, { params });
  }

  public async payTicket(barCode: number, ticketId: number): Promise<Ticket> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets/${barCode}/pay/${ticketId}`;
    return await this.http.put(url);
  }

  public async returnTicket(barcode: number): Promise<Ticket> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets/${barcode}/return`;
    return await this.http.put(url);
  }

  public async cancelTicketByBarcode(barcode: number): Promise<Ticket> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets/${barcode}/cancel`;
    return await this.http.put(url);
  }

  public async printTicket(ticketId: number): Promise<Ticket> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets/${ticketId}/print`;
    return await this.http.put(url);
  }

  public async getTicketDetails(
    params: TicketDetails,
  ): Promise<ManagerResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/tickets/qr`;
    return await this.http.get(url, { params });
  }
}

export const ticketsController = new TicketsController({ url: env.httpUrl });
