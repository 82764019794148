import React from "react";
import { observer } from "mobx-react-lite";

import {
  CheckTicketModal,
  EditBalanceLimitModal,
} from "../../components/modals";
import { useStore } from "../../../hooks";
import { toJS } from "mobx";

interface GlobalModalsProps {}

export const GlobalModals: React.FC<GlobalModalsProps> = observer(() => {
  const {
    modalStore: {
      isOpenBalanceAndLimitModal,
      checkModal: { open: checkModalOpen },
    },
  } = useStore();

  return (
    <>
      {isOpenBalanceAndLimitModal && <EditBalanceLimitModal />}
      {checkModalOpen && <CheckTicketModal />}
    </>
  );
});
