/* eslint-disable no-loop-func */
import { QueryModel } from "../models";
import { TypeChecker } from "./type-checker";

export const combineQuery = (queries: QueryModel, returnPathName = true) => {
  if (typeof queries === "string") {
    return queries;
  } else {
    let query = "?";
    for (let key in queries) {
      if (key) {
        if (Array.isArray(queries[key])) {
          queries[key].forEach((val: any) => {
            query += `${key}=${encodeURIComponent(val)}&`;
          });
        } else {
          if (
            !TypeChecker.isUndefined(queries[key]) &&
            !TypeChecker.isNull(queries[key]) &&
            !TypeChecker.isEmptyString(queries[key])
          ) {
            query += `${key}=${encodeURIComponent(queries[key])}&`;
          }
        }
      }
    }
    return returnPathName
      ? query.slice(0, -1) || window.location.pathname
      : query.slice(0, -1);
  }
};
