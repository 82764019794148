import React, { MutableRefObject, useRef } from 'react';

import { useOutsideClick, useStore } from '../../../../../../../hooks';
import { IconTypes } from '../../../../../../../enums';
import { T } from '../../../../../../../constants';
import { UserAction } from '../user-action';

interface UserActionsPopoverProps {
  onOutsideClick: () => void;
  openerRef: MutableRefObject<HTMLDivElement | null>;
}

export const UserActionsPopover: React.FC<UserActionsPopoverProps> = ({
  openerRef,
  onOutsideClick
}) => {

  const popoverRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(onOutsideClick, popoverRef, openerRef);

  const { authStore } = useStore();

  return (
    <div className="username-drop-cont-inner" ref={popoverRef}>
      <div className="username-drop-down-container">
        <div className="scroll-holder">
          <div className="scrollable-container static-scroll">
            <UserAction
              name={T.LOGOUT}
              icon={IconTypes.Logout}
              onClick={() => authStore.logout()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
