import { KeyValuePair } from "../models";
import { toJS } from "mobx";

/**
 * Replace translation dynamic values string.
 * This function returns value replaced string.
 */
export const setTranslationValues = (
  text = "",
  valuesObj: KeyValuePair = {},
): string => {
  let result = text;
  for (const key in valuesObj) {
    result = result.replaceAll(`{{${key}}}`, `${valuesObj[key]}`);
  }
  return result;
};

export const ReplaceVirtualTranslationKey = (
  value: string,
  translationSymbols: any | undefined,
  t: any,
) => {
  if (value && value.indexOf("{") > -1 && translationSymbols) {
    Object.keys(translationSymbols).forEach((key) => {
      value = value.replaceAll(String(key), t(translationSymbols[key]));
    });
  }
  return value;
};
