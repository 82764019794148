import React from "react";

import { TableHeaderFilters } from "./table-header/table-header-filters";
import { TableMainContent } from "./table-main-content";
import { useTableColumns } from "../../../../hooks";
import { TableColumn } from "../../../../models";
import { TableHeader } from "./table-header";
import { TableFooter } from "./table-footer";
import { noop } from "../../../../constants";

interface TableProps {
  page: number;
  title: string;
  count: number;
  rowKey?: string;
  loading?: boolean;
  isDrawPrintIcon?: boolean;
  rows: Array<any>;
  selectorValue: number;
  filters?: React.ReactNode;
  columns: Array<TableColumn>;
  headerActions?: React.ReactNode;
  onRowClick?: (row: any, index: number) => void;
  onPaginationChange: (page: number, selectorValue: number) => void;
}

export const Table: React.FC<TableProps> = ({
  rows,
  page,
  count,
  filters,
  columns,
  title = "",
  selectorValue,
  headerActions,
  rowKey = "id",
  loading = false,
  onRowClick = noop,
  onPaginationChange,
  isDrawPrintIcon,
}) => {
  const { cols } = useTableColumns(columns);
  const countPage = Math.ceil(count / selectorValue);

  return (
    <div className="main-structure-wrapper">
      <div className="main-structure-top-wrapper">
        <TableHeader title={title} actions={headerActions} />
        <TableHeaderFilters filters={filters} />
      </div>
      <TableMainContent
        rows={rows}
        columns={cols}
        rowKey={rowKey}
        loading={loading}
        onRowClick={onRowClick}
        isDrawPrintIcon={isDrawPrintIcon}
      />
      <TableFooter
        count={countPage}
        currentPage={page}
        selectorValue={selectorValue}
        onPaginationChange={onPaginationChange}
      />
    </div>
  );
};
