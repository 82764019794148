import { T } from "./translation-keys";

export const MONTHS = [
  T.JANUARY,
  T.FEBRUARY,
  T.MARCH,
  T.APRIL,
  T.MAY,
  T.JUNE,
  T.JULY,
  T.AUGUST,
  T.SEPTEMBER,
  T.OCTOBER,
  T.NOVEMBER,
  T.DECEMBER,
];

export const WEEK_DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const DEFAULT_TIME_START_DATE = '00:00:00';
export const DEFAULT_TIME_END_DATE = '23:59:59';
