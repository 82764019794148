export enum IconTypes {
  Date = "icon-date",
  Info = "icon-info",
  Data = "icon-data",
  Done = "icon-done",
  Moon = "icon-moon",
  Bento = "icon-bento",
  Games = "icon-games",
  Close = "icon-close",
  Search = "icon-search",
  Logout = "icon-log-out",
  User = "icon-user-icon",
  Balance = "icon-balance",
  Warning = "icon-warning",
  Arrow = "icon-bottom-stroke",
  LightMode = "icon-light-mode",
  DoneStroke = "icon-round-done",
  PlacedBets = "icon-placed-bet",
  ArrowLeft = "icon-left-stroke",
  InfoStroke = "icon-info-stroke",
  ArrowRight = "icon-right-stroke",
  MoneyHand = "icon-money-hand",
  Pending = "icon-pending",
  Won = "icon-gameWon",
  Lost = "icon-gameLost",
  Returned = "icon-returned",
  Unknown = "",
}
