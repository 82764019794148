import React, { useCallback, useMemo } from "react";

import { IconTypes, TicketStatus } from "../../../../../enums";
import TicketResultHeader from "../ticket-result-header";
import { BetType, Ticket } from "../../../../../models";
import { useTranslation } from "../../../../../hooks";
import { Button, Icon } from "../../../shared";
import { BARCODE_LENGTH, DATE_FORMAT_BACK, T } from "../../../../../constants";
import { MarketList } from "./market-list";
import { setTranslationValues, utcToLocal } from "../../../../../helpers";
import {
  GAMES,
  GAMES_CANCEL_STAGE,
  VirtualSportGameIds,
} from "../../../../../constants/games";
import { env } from "../../../../../environment";

interface CheckTicketResultProps {
  ticket: Ticket;
  payLoading: boolean;
  barcode: string;
  maxPayout?: number;
  inactiveCancelBtn: boolean;
  isPinRequiredForPayout: boolean;
  onPayClick: () => void;
  cancelTicket: () => void;
  onCheckAgainClick: () => void;
  setBarCode: (barCode: string) => void;
}

export const CheckTicketResult: React.FC<CheckTicketResultProps> = ({
  barcode,
  ticket,
  onPayClick,
  maxPayout,
  setBarCode,
  cancelTicket,
  payLoading,
  onCheckAgainClick,
  inactiveCancelBtn,
  isPinRequiredForPayout,
}) => {
  const t = useTranslation();

  const {
    status,
    paidAmount,
    ticketInfos,
    betType,
    roundStart,
    returnedManually,
    barcode: ticketBarCode,
    paymentDate,
    winAmount,
  } = ticket;

  const isWon = status === TicketStatus.Won;
  const isPaid = !!paidAmount;
  const showPayButton = isWon && !isPaid;
  const isExpress = betType === BetType.Express;

  const handleCheckAgainClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onCheckAgainClick();
    },
    [],
  );

  const showCancelBet = useMemo(() => {
    // TODO hard code need hide code   (VirtualSportGameIds.includes(+ticket.gameId) || GAMES.includes(+ticket.gameId))

    return (
      new Date(utcToLocal(roundStart, DATE_FORMAT_BACK)) >
        new Date(utcToLocal(new Date(), DATE_FORMAT_BACK)) &&
      !returnedManually &&
      status !== TicketStatus.Returned &&
      (VirtualSportGameIds.includes(+ticket.gameId) ||
        !GAMES.includes(+ticket.gameId))
    );
  }, [roundStart, returnedManually]);

  const payTicketWithEnter = (e: React.KeyboardEvent) => {
    console.log(e.key);
  };

  const barCodeChange = (value: string) => {
    if (value.length > BARCODE_LENGTH) return;
    setBarCode(value);
  };

  const isWinAmountGreaterThanMaxPayoutAmount = useMemo(() => {
    if (maxPayout && winAmount) {
      return winAmount > maxPayout;
    }
  }, [maxPayout, winAmount]);

  return (
    <>
      <div className="wrapper-pp">
        {isWon && (
          <>
            <div className="ticket-cont">
              <div className="ticket-icon-block">
                {isPaid ? (
                  <Icon
                    type={IconTypes.DoneStroke}
                    className="popup-ticket-icon-2"
                  />
                ) : (
                  <Icon
                    type={IconTypes.MoneyHand}
                    className="popup-ticket-icon"
                  />
                )}
              </div>
            </div>
            <div className="fot-title-text">
              <p className="popup-title-text-check">
                {t(isPaid ? T.TICKET_IS_PAID : T.TICKET_SHOULD_BE_PAID_OUT)}
              </p>
            </div>
          </>
        )}
        <TicketResultHeader
          ticket={ticket}
          isExpress={isExpress}
          returnedManually={returnedManually}
          isPinRequiredForPayout={isPinRequiredForPayout}
        />
        <MarketList
          isExpress={isExpress}
          ticketInfos={ticketInfos}
          ticketStatus={status}
        />
      </div>
      {/*TODO need hide code && !GAMES.includes(+ticket.gameId) &&*/}
      <div className="pay-ticket-footer">
        {isPinRequiredForPayout &&
        ((!paymentDate && status === TicketStatus.Won) ||
          (status === TicketStatus.Pending &&
            !GAMES.includes(+ticket.gameId))) ? (
          <div className="popupInputHolder">
            <input
              type="text"
              className="popupInput"
              placeholder={t(T.BARCODE)}
              onChange={(e) => barCodeChange(e.target.value)}
              value={+barcode || ""}
              autoFocus
              onKeyPress={payTicketWithEnter}
            />
          </div>
        ) : null}

        {isWinAmountGreaterThanMaxPayoutAmount ? (
          <p className="max-payout-info">
            {setTranslationValues(t(T.MAX_PAY_OUT_AMOUNT_RANGE), {
              amount: maxPayout,
            })}
          </p>
        ) : null}

        <div className="bet-button-block">
          <Button
            text={T.CHECK_AGAIN}
            className="pp-button"
            onClick={handleCheckAgainClick}
          />
          {showPayButton && (
            <Button
              onClick={onPayClick}
              disabled={
                (isPinRequiredForPayout && !+barcode) ||
                (!isPinRequiredForPayout && !+ticketBarCode) ||
                payLoading
              }
              className="pp-button active"
              loading={payLoading}
              text={T.PAY}
            />
          )}
        </div>
      </div>
      {/*<div className="bet-button-block btn-under">*/}
      {/*  <button type="button" className="common-button pp-button ">repeat bet</button>*/}
      {/*</div>*/}
      <div className="bet-button-block bet-button-block-2">
        {/*showCancelBet && GAMES.includes(+ticket.gameId) ?*/}
        {showCancelBet ? (
          <button
            className={`pp-button cl-button ${inactiveCancelBtn ? "disabled" : ""}`}
            onClick={cancelTicket}
          >
            {t(T.CANCEL_TICKET)}
          </button>
        ) : null}

        {/*<button className="pp-button rp-button">REPEAT BET</button>*/}
      </div>
    </>
  );
};
