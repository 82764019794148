import { PrintTypes } from "./../enums/print-types";
import { makeAutoObservable, observable, action } from "mobx";

import { TicketAccepted, UserWallet } from "../models";
import { RootStore } from "./index";
import { ws } from "../api";

export class LiveUpdateStore {
  private rootStore: RootStore;

  public reconnecting: boolean = false;
  public connectionLost: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      reconnecting: observable,
      connectionLost: observable,
      setReconnecting: action,
      setConnectionLost: action,
    });
  }

  public setReconnecting(value: boolean): void {
    this.reconnecting = value;
  }

  public setConnectionLost(value: boolean): void {
    this.connectionLost = value;
  }

  public async init(): Promise<void> {
    await ws.init();
    this.listenEvents();
  }

  private handleWalletUpdate(data: UserWallet): void {
    this.rootStore.userStore.updateWallet(data);
  }

  private handleTicketAccepted(data: TicketAccepted): void {
    this.rootStore.ticketsStore.setTicketPrintData({
      type: PrintTypes.Bet,
      data,
    });
  }

  private handleClose(): void {
    this.setConnectionLost(true);
  }

  private listenEvents(): void {
    ws.onClose(this.handleClose.bind(this));
    ws.onWalletUpdate(this.handleWalletUpdate.bind(this));
    ws.onTicketAccepted(this.handleTicketAccepted.bind(this));
  }
}
