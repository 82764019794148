import { Button } from "../../shared";
import { useRef, useState } from "react";
import { sha512WithKeyAndSalt } from "../../../../helpers/sha512";
import { useTranslation } from "../../../../hooks";
import { T } from "../../../../constants";

interface FairnessProps {
  hash: string;
  salt: string;
  fairnessKey: string;
}
const Fairness: React.FC<FairnessProps> = ({ hash, fairnessKey, salt }) => {
  const t = useTranslation();

  const keyRef = useRef<HTMLInputElement>(null);
  const saltRef = useRef<HTMLInputElement>(null);

  const [keyValue, setKeyValue] = useState<string>();
  const [saltValue, setSaltValue] = useState<string>();
  const [hashCode, setHashCode] = useState<string>();

  const generateHashCode = () => {
    sha512WithKeyAndSalt(keyValue || fairnessKey, saltValue || salt).then(
      (res) => {
        setHashCode(res);
      },
    );
  };

  return (
    <div className="qrResultMainSection">
      <div className="qrResultMainSectionTitle">
        <span className="qrResultMainSectionTitleTxt">{t(T.FAIRNESS)}</span>
      </div>
      <div className="qrResultInputsHolder">
        <div className="qrResultInputsWrapper">
          <div className="qrResultInputsContainer">
            <div
              className="qrResultInputCopy"
              onClick={() => setKeyValue(keyRef.current?.value)}
            >
              <i className="icon-qrCopy copyIcon" />
            </div>
            <input
              readOnly
              ref={keyRef}
              id="fairnessKey"
              placeholder={" "}
              className="qrResultInput"
              type="text"
              value={fairnessKey}
            />
            <span className="qrResultPlaceholderImit">{t(T.KEY)}</span>
          </div>
          <div className="qrResultInputsContainer">
            <div
              className="qrResultInputCopy"
              onClick={() => setSaltValue(saltRef.current?.value)}
            >
              <i className="icon-qrCopy copyIcon" />
            </div>
            <input
              readOnly
              ref={saltRef}
              placeholder={" "}
              className="qrResultInput"
              type="text"
              value={salt}
            />
            <span className="qrResultPlaceholderImit">{t(T.SALT)}</span>
          </div>
        </div>
        <div className="qrResultInputsWrapper">
          <div className="qrResultInputsContainer">
            <input
              readOnly
              placeholder={" "}
              className="qrResultInput"
              type="text"
              value={hash}
            />
            <span className="qrResultPlaceholderImit">{t(T.HASH_CODE)}</span>
          </div>
        </div>
      </div>

      <div className="qrResultInputsLine" />
      <p className="qrResultMainSectionTitle">
        <span className="qrResultMainSectionTitleTxt">
          {t(T.CHECK_HASH_CODE)}
        </span>
      </p>
      <div className="qrResultInputsHolder">
        <div className="qrResultInputsWrapper">
          <div className="qrResultInputsContainer">
            <input
              onChange={(e) => {
                setKeyValue(e.target.value);
              }}
              placeholder={" "}
              className="qrResultInput"
              type="text"
              value={keyValue}
            />
            <span className="qrResultPlaceholderImit">{t(T.KEY)}</span>
          </div>
          <div className="qrResultInputsContainer">
            <input
              onChange={(e) => {
                setSaltValue(e.target.value);
              }}
              placeholder={" "}
              className="qrResultInput"
              value={saltValue}
              type="text"
            />
            <span className="qrResultPlaceholderImit">{t(T.SALT)}</span>
          </div>
        </div>
        <div className="qrResultInputsWrapper">
          <div className="qrResultInputsContainer">
            <input
              onChange={() => {}}
              placeholder={" "}
              className="qrResultInput"
              type="text"
              value={hashCode}
            />
            <span className="qrResultPlaceholderImit">{t(T.HASH_CODE)}</span>
          </div>
        </div>
      </div>
      <div className="qrResultBtnContainer">
        <Button
          text={t(T.CHECK)}
          className={"qrResultCheckBtn"}
          type={"button"}
          disabled={!(keyValue && saltValue)}
          onClick={generateHashCode}
        />
      </div>
    </div>
  );
};

export default Fairness;
