import React from "react";

import { useTranslation } from "../../../../hooks";

import LoadingCircle from "../../../../assets/images/loading-circle.png";

interface LoaderProps {
  text?: string;
}

export const Loader: React.FC<LoaderProps> = ({ text = "" }) => {
  const t = useTranslation();

  return (
    <div className="loader-wrapper">
      <div className="loader-container">
        <img src={LoadingCircle} alt="loading-circle" className="loader-icon" />
        <p className="loader-info">{t(text)}</p>
      </div>
    </div>
  );
};
