export enum HttpResponseStatus {
  Default = "Default",
  Pending = "Pending",
  Error = "Error",
  Done = "Done",
}

export class HttpResponse<T> {
  public initialState: T;
  public data: T;
  public status: HttpResponseStatus;
  public error?: string;
  paidAmount: Boolean | undefined;

  constructor(initialState: T, status = HttpResponseStatus.Pending) {
    this.initialState = initialState;
    this.data = initialState;
    this.status = status;
  }

  public get isDone(): boolean {
    return this.status === HttpResponseStatus.Done;
  }

  public get isLoading(): boolean {
    return this.status === HttpResponseStatus.Pending;
  }

  public fetching(resetData = false): HttpResponse<T> {
    if (resetData) {
      this.data = this.initialState;
    }
    this.status = HttpResponseStatus.Pending;
    return this.clone();
  }

  public fetched(data: T): HttpResponse<T> {
    this.data = data;
    this.status = HttpResponseStatus.Done;
    return this.clone();
  }

  public failed(error: string): HttpResponse<T> {
    this.error = error;
    this.status = HttpResponseStatus.Error;
    return this.clone();
  }

  private clone(): HttpResponse<T> {
    const self = { ...this };
    Object.setPrototypeOf(self, HttpResponse.prototype);
    return self;
  }
}
