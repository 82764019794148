import React from "react";

import { MarketListItem } from "./market-list-item";
import { TicketInfo } from "../../../../../../models";
import { ReplaceVirtualTranslationKey } from "../../../../../../helpers";
import { useTranslation } from "../../../../../../hooks";

interface MarketListProps {
  isExpress: boolean;
  ticketStatus: number;
  ticketInfos: Array<TicketInfo>;
}

export const MarketList: React.FC<MarketListProps> = ({
  ticketInfos,
  isExpress,
  ticketStatus,
}) => {
  const t = useTranslation();

  return (
    <div className="bet-section-wrapper">
      <div className="scroll-holder">
        <div className="scrollable-container static-scroll">
          {ticketInfos.map(
            ({
              marketName,
              odds,
              status,
              betAmount,
              winAmount,
              translationSymbols,
              result,
              gameName,
              roundId,
              numbers,
            }) => (
              <MarketListItem
                odds={odds}
                status={status}
                key={marketName}
                gameName={gameName}
                isExpress={isExpress}
                betAmount={betAmount}
                winAmount={winAmount}
                roundId={roundId}
                marketName={
                  numbers ||
                  ReplaceVirtualTranslationKey(
                    t(translationSymbols?.marketName || marketName),
                    translationSymbols,
                    t,
                  )
                }
                selectionName={ReplaceVirtualTranslationKey(
                  t(translationSymbols?.selectionName),
                  translationSymbols,
                  t,
                )}
                // result={JSON.parse(JSON.parse(result)?.result)}
                handicap={translationSymbols?.handicap}
                ticketStatus={ticketStatus}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};
