import notFoundImg from "../../../assets/images/not-found.png";
import { useTranslation } from "../../../hooks";
import { RoutePaths, T } from "../../../constants";
import { NavLink } from "react-router-dom";
interface NotFoundProps {
  hideBackToLink?: boolean;
}

const NotFound: React.FC<NotFoundProps> = ({ hideBackToLink }) => {
  const t = useTranslation();

  return (
    <div className="not-found-page">
      <div>
        <div className="not-found-info-wrp">
          <span className="not-found-info">{t(T.ERROR)}</span>
          <div className="not-found-img-block">
            <img src={notFoundImg} alt="" />
          </div>
          <span className="not-found-info">{t(T.PAGE_NOT_FOUND)}</span>
        </div>
        {!hideBackToLink ? (
          <NavLink
            to={RoutePaths.Game}
            key={RoutePaths.Game}
            className={({ isActive }) =>
              `header-left-side-column ${isActive ? "active" : ""}`
            }
          >
            <button type="button" className="back-to-btn">
              <span className="back-to-btn-txt">{t(T.BACK_TO_DASHBOARD)}</span>
            </button>
          </NavLink>
        ) : null}
      </div>
    </div>
  );
};

export default NotFound;
