import React from "react";

import { LoginForm } from "./login-form";
import { Welcome } from "./welcome";
import NewPassword from "./new-password";
import TokenExpired from "../token-expired";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  return (
    <div className="login-wrapper">
      <Welcome />
      <LoginForm />
      {/*<NewPassword />*/}
    </div>
    // <TokenExpired />
  );
};

export default Login;
