// export const GAMES = [75, 77, 78, 79, 80, 81, 94, 96, 95, 118, 119, 156141];
export const GAMES = [156141, 156140, 150];

export const GAMES_CANCEL_STAGE = [156141, 156140, 150, 22, 59, 90];
export const NOT_AVAILABLE_GAMES_FAIRNESS = [90, 59];

export const VirtualSportGameIds: Array<number> = [
  77, 79, 81, 78, 94, 80, 75, 76, 93, 118, 119,
];

export const GameIds = {
  Keno: 150,
  OddBall: 22,
  Baccarat: 59,
  AndarBahar: 90,
  Roulette: 156140,
  DoubleWheel: 156141,
  VirtualFootball: 75,
  VirtualDragRacing: 80,
  VirtualSpinToWin: 118,
  VirtualHorseRacing: 77,
  VirtualCycleRacing: 79,
  VirtualAutoRoulette: 95,
  VirtualMarbleRacing: 81,
  VirtualPenaltyKicks: 76,
  VirtualGreyhoundRacing: 78,
  VirtualGreyhound8Racing: 94,
  VirtualSpinToWinDeluxe: 119,
  VirtualPrerecorded10Harness: 93,
};

export const VirtualRaceClassNamesByIds = {
  [GameIds.VirtualCycleRacing]: "virtual-cycle-racing",
  [GameIds.VirtualHorseRacing]: "virtual-horse-racing ",
  [GameIds.VirtualMarbleRacing]: "virtual-marble-racing",
  [GameIds.VirtualGreyhoundRacing]: "virtual-greyhound-racing",
  [GameIds.VirtualGreyhound8Racing]: "virtual-greyhound-8-racing",
  [GameIds.VirtualPrerecorded10Harness]: "virtual-harness-racing",
};

export const spinNumbers = [
  0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24,
  16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26,
];

interface RouletteEntry {
  color: string;
  sector: string | null;
}

// TODO need improvements
export const rouletteData: { [key: number]: RouletteEntry } = {
  0: { color: "colorGreen", sector: null },

  32: { color: "colorRed", sector: "A" },
  15: { color: "colorBlack", sector: "A" },
  19: { color: "colorRed", sector: "A" },
  4: { color: "colorBlack", sector: "A" },
  21: { color: "colorRed", sector: "A" },
  2: { color: "colorBlack", sector: "A" },

  25: { color: "colorRed", sector: "B" },
  17: { color: "colorBlack", sector: "B" },
  34: { color: "colorRed", sector: "B" },
  6: { color: "colorBlack", sector: "B" },
  27: { color: "colorRed", sector: "B" },
  13: { color: "colorBlack", sector: "B" },

  36: { color: "colorRed", sector: "C" },
  11: { color: "colorBlack", sector: "C" },
  30: { color: "colorRed", sector: "C" },
  8: { color: "colorBlack", sector: "C" },
  23: { color: "colorRed", sector: "C" },
  10: { color: "colorBlack", sector: "C" },

  5: { color: "colorRed", sector: "D" },
  24: { color: "colorBlack", sector: "D" },
  16: { color: "colorRed", sector: "D" },
  33: { color: "colorBlack", sector: "D" },
  1: { color: "colorRed", sector: "D" },
  20: { color: "colorBlack", sector: "D" },

  14: { color: "colorRed", sector: "E" },
  31: { color: "colorBlack", sector: "E" },
  9: { color: "colorRed", sector: "E" },
  22: { color: "colorBlack", sector: "E" },
  18: { color: "colorRed", sector: "E" },
  29: { color: "colorBlack", sector: "E" },

  7: { color: "colorRed", sector: "F" },
  28: { color: "colorBlack", sector: "F" },
  12: { color: "colorRed", sector: "F" },
  35: { color: "colorBlack", sector: "F" },
  3: { color: "colorRed", sector: "F" },
  26: { color: "colorBlack", sector: "F" },
};
export const MASTS = ["joker", "club", "heart", "spade", "diamond"];
