import React from "react";

interface PageProps {
  className?: string;
  onClick: () => void;
  page: number | string;
}

export const Page: React.FC<PageProps> = ({
  page,
  onClick,
  className = "",
}) => {
  return (
    <div onClick={onClick} className={className}>
      <p className="pagination-item">{page}</p>
    </div>
  );
};
