import { T } from "./translation-keys";
import { BetStatus, IconTypes, TicketStatus } from "../enums";

export const BetStatusNames = {
  [BetStatus.Lose]: T.LOSE,
  [BetStatus.Win]: T.WIN,
  [BetStatus.Pending]: T.PENDING,
  [BetStatus.Canceled]: T.CANCELED,
  [BetStatus.Unknown]: T.UNKNOWN,
  [BetStatus.Calculation]: T.CALCULATION,
  [BetStatus.OnCancel]: T.ON_CANCEL,
};

export const BetStatusClassNames = {
  [BetStatus.Lose]: "state-red",
  [BetStatus.Win]: "state-win",
  [BetStatus.Pending]: "state-yellow",
  [BetStatus.Canceled]: "state-red",
  [BetStatus.Unknown]: "",
  [BetStatus.Calculation]: "",
  [BetStatus.OnCancel]: "",
};

export const BetStatusIconNames = {
  [BetStatus.Win]: IconTypes.Won,
  [BetStatus.Lose]: IconTypes.Lost,
  [BetStatus.Pending]: IconTypes.Pending,
  [BetStatus.Canceled]: IconTypes.Returned,
  [BetStatus.Unknown]: IconTypes.Unknown,
  [BetStatus.Calculation]: IconTypes.Unknown,
  [BetStatus.OnCancel]: IconTypes.Unknown,
};
