import {
  DoubleWheelColors,
  DoubleWheelColorsOdds,
} from "../../../../../constants/fairness-games";
import { DoubleWheelColorsIndex } from "../../../../../enums/fairness-games";

interface DoubleWheelProps {
  result: string;
}
const DoubleWheel: React.FC<DoubleWheelProps> = ({ result }) => {
  const { wheels } = JSON.parse(result);
  const { firstWheelColor, secondWheelColor } = wheels[0];

  const drawOdds = (index: number) => {
    if (index === DoubleWheelColorsIndex.Wild) {
      return DoubleWheelColorsOdds[DoubleWheelColorsIndex.Wild];
    } else {
      return DoubleWheelColorsOdds[index];
    }
  };
  return (
    <>
      <div className="doubleWheelArrowImgHolder">
        <div
          className={`qrResultDoubleWheelImgHolder left ${DoubleWheelColors[firstWheelColor]}`}
        >
          <span className="qrResultDoubleWheelMultiplierTxt txtLeft">
            {drawOdds(firstWheelColor)}
          </span>
        </div>
        <div className="doubleArrowImg" />
        <div
          className={`qrResultDoubleWheelImgHolder right ${DoubleWheelColors[secondWheelColor]}`}
        >
          <span className="qrResultDoubleWheelMultiplierTxt txtRight">
            {drawOdds(secondWheelColor)}
          </span>
        </div>
      </div>
    </>
  );
};

export default DoubleWheel;
