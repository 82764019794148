import { GetTranslationsRequest, Translations } from "../../../models";
import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";

class TranslationsController extends BaseApiService {
  public async getTranslations(
    params: GetTranslationsRequest,
  ): Promise<Translations> {
    const url = `${this.url}/api/${ApiVersions.V1}/translations`;
    const headers = { applicationId: env.applicationId };
    return await this.http.get(url, { params, headers });
  }
}

export const translationsController = new TranslationsController({
  url: env.translationsHttpUrl,
});
