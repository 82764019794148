import { IsAuthLayer, IsNotAuthLayer } from "./layers";
import { defineGuards } from "./define-guards";
import { RoutePaths } from "../constants";
import Login from "../view/pages/login";
import { RouteModel } from "../models";
import { App } from "../view/App";
import { IsQRTicketLayer } from "./layers/isi-QR-ticket-layer";
import QRTicketDetails from "../view/pages/QR-ticket-details";

export const AuthRoutes: Array<RouteModel> = [
  {
    path: RoutePaths.Login,
    component: () => defineGuards([IsNotAuthLayer], Login),
  },
  {
    path: RoutePaths.QRTicketDetails,
    component: () => defineGuards([IsQRTicketLayer], QRTicketDetails),
  },
  {
    path: RoutePaths.WildCard,
    component: () => defineGuards([IsAuthLayer], App),
  },
];
