import { makeObservable, observable, action } from "mobx";

import { RootStore } from ".";

interface CheckModal {
  data: string;
  open: boolean;
}

export class ModalStore {
  private rootStore?: RootStore;

  public checkModal: CheckModal = { open: false, data: "" };
  public returnModalOpen = false;

  public isOpenBalanceAndLimitModal: boolean = false;

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isOpenBalanceAndLimitModal: observable,
      returnModalOpen: observable,
      checkModal: observable,
      setCheckModal: action.bound,
      setIsOpenBalanceAndLimitModal: action.bound,
      setReturnModalOpen: action.bound,
    });
  }

  public setIsOpenBalanceAndLimitModal(bool: boolean): void {
    this.isOpenBalanceAndLimitModal = bool;
  }

  public setCheckModal(value: CheckModal): void {
    this.checkModal = value;
  }

  public setReturnModalOpen(value: boolean): void {
    this.returnModalOpen = value;
  }
}
