import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { ModalContainer, Providers, Toaster, Splash } from "./containers";
import { Print } from "./components/global";
import { AppContainer } from "./containers";
import { AuthRoutes } from "../routing";
import posthog from "posthog-js";
import { IS_UNIVERSAL_PARTNER } from "../constants/host-name";

interface AppWrapperProps {}

export const AppWrapper: React.FC<AppWrapperProps> = () => {
  useEffect(() => {
    posthog.init("phc_eGnqDPvlMh8S4Kcflx2RfmZe0VOEm4oIRjODQjLlyw4", {
      api_host: "https://us.i.posthog.com",
      person_profiles: "always",
    });

    if (IS_UNIVERSAL_PARTNER) {
      document.title = "Virtuales Pro";

      const favicon = document.querySelector(
        'link[rel="icon"]',
      ) as HTMLLinkElement | null;

      if (favicon) {
        favicon.href = `${process.env.PUBLIC_URL}/universalSoftLogo.png`;
      }
    }
  }, []);

  return (
    <AppContainer>
      <Providers>
        <Splash>
          <Routes>
            {AuthRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
          <Toaster />
          <ModalContainer />
        </Splash>
        <Print />
      </Providers>
    </AppContainer>
  );
};
