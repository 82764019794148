import { DatePickerDay } from "../models";

// returns number of month days
export const getMonthDaysCount = (year: number, month: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

// returns last days of previous month, days of selected month and first days of next month
export const getMonthDays = (
  year: number,
  month: number,
): Array<DatePickerDay> => {
  const result: Array<DatePickerDay> = [];
  const currentDaysCount = getMonthDaysCount(year, month);
  const prevMonthDaysCount = getMonthDaysCount(year, month - 1);
  const firstDay = new Date(year, month);
  const firstDayWeekIndex = firstDay.getDay();

  // previous month days
  for (let i = (firstDayWeekIndex - 1 + 7) % 7; i > 0; i--) {
    result.push({
      disabled: true,
      date: new Date(year, month - 1, prevMonthDaysCount - i + 1),
    });
  }

  // current month days
  for (let i = 0; i < currentDaysCount; i++) {
    result.push({
      disabled: false,
      date: new Date(year, month, i + 1),
    });
  }

  // next month days
  let i = 1;
  while (result.length % 7 !== 0) {
    result.push({
      disabled: true,
      date: new Date(year, month + 1, i++),
    });
  }

  return result;
};
