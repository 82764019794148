import React from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";

import { StoreContext, TranslationContext } from "../../../contexts";
import { rootStore } from "../../../store";

interface ProvidersProps {
  children: React.ReactNode;
}

export const Providers: React.FC<ProvidersProps> = observer(({ children }) => {
  const { translationsStore } = rootStore;
  const {
    translations: { data: translations },
  } = translationsStore;

  return (
    <StoreContext.Provider value={rootStore}>
      <TranslationContext.Provider value={translations}>
        <BrowserRouter>{children}</BrowserRouter>
      </TranslationContext.Provider>
    </StoreContext.Provider>
  );
});
