import { useCallback, useEffect, useMemo, useState } from "react";

import { WinTicketContentFooter } from "./win-ticket-content-footer";
import { Ticket as TicketModel } from "../../../../../models";
import {
  ReplaceVirtualTranslationKey,
  truncWithPrecision,
} from "../../../../../helpers";
import { BetTypeNames } from "../../../../../constants";
import { BetInfo } from "../ticket/bet-info";
import { Market } from "../ticket/market";
import { Ticket } from "../ticket";
import { useTranslation } from "../../../../../hooks";
import { toJS } from "mobx";
import { TicketConfig } from "../../../../../models/partnerResponse";

interface WinTicketProps {
  data: TicketModel;
  isExpress: boolean;
  ticketConfig: TicketConfig;
}

export const WinTicket: React.FC<WinTicketProps> = ({
  data,
  isExpress,
  ticketConfig,
}) => {
  const t = useTranslation();
  const {
    barcode,
    ticketInfos,
    winAmount,
    amount,
    paymentDate,
    betType,
    ticketId,
  } = data;

  const [ticketLoaded, setTicketLoaded] = useState(false);

  useEffect(() => {
    if (ticketLoaded) {
      window.print();
    }
  }, [ticketLoaded]);

  const firstMarketData = useMemo(() => {
    const [firstMarket] = ticketInfos;
    return firstMarket;
  }, [ticketInfos]);

  const gameName = firstMarketData?.gameName || "";
  const currencyCode = firstMarketData?.currency || "";
  const roundId = firstMarketData?.roundId;

  const handleTicketLoad = useCallback(() => {
    setTicketLoaded(ticketConfig?.showLogo || true);
  }, []);

  const totalOdd = useMemo(() => {
    const odd = ticketInfos.reduce<number>((acc, market) => {
      return acc * market.odds;
    }, 1);
    return truncWithPrecision(odd);
  }, [ticketInfos]);

  const betTypeName = useMemo(() => {
    return isExpress
      ? `${BetTypeNames[betType]} (${ticketInfos.length})`
      : BetTypeNames[betType];
  }, [isExpress, ticketInfos]);

  return (
    <Ticket
      barcode={barcode}
      onTicketLoad={handleTicketLoad}
      showLogo={ticketConfig?.showLogo}
      logo={ticketConfig?.logo}
    >
      <BetInfo
        barCode={barcode}
        roundId={roundId}
        date={paymentDate}
        ticketId={ticketId}
        gameName={gameName}
        isExpress={isExpress}
        betTypeName={betTypeName}
      />
      {ticketInfos.map(
        (
          {
            betAmount,
            odds,
            marketName,
            currency,
            winAmount,
            gameName,
            translationSymbols,
            roundId,
            eventName,
            numbers,
          },
          index,
        ) => (
          <Market
            odd={odds}
            showWinAmount
            key={marketName}
            roundId={roundId}
            gameName={gameName}
            isExpress={isExpress}
            winAmount={winAmount}
            betAmount={betAmount}
            eventName={
              translationSymbols?.homeTeam
                ? `${ReplaceVirtualTranslationKey(
                    t(translationSymbols?.homeTeam),
                    translationSymbols,
                    t,
                  )} - ${ReplaceVirtualTranslationKey(
                    t(translationSymbols?.awayTeam),
                    translationSymbols,
                    t,
                  )}`
                : ""
            }
            marketName={
              numbers ||
              ReplaceVirtualTranslationKey(
                t(translationSymbols?.marketName || marketName),
                translationSymbols,
                t,
              )
            }
            selectionName={ReplaceVirtualTranslationKey(
              t(translationSymbols?.selectionName),
              translationSymbols,
              t,
            )}
            currencyCode={currency}
            showLine={index !== ticketInfos.length - 1}
            ticketConfig={ticketConfig}
          />
        ),
      )}
      <WinTicketContentFooter
        totalOdd={totalOdd}
        totalWin={winAmount}
        isExpress={isExpress}
        totalBetAmount={amount}
        currencyCode={currencyCode}
        showTotalWinAmount={
          ticketConfig?.showExpectedMaxWin || ticketConfig?.shortTicket
        }
      />
    </Ticket>
  );
};
