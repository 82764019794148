import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";
import {
  PartnerBusinessResponse,
  PartnerResponse,
} from "../../../models/partnerResponse";

class PartnerController extends BaseApiService {
  public async getPartner(): Promise<PartnerResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/partner`;
    return await this.http.get(url);
  }

  public async getPartnerBusiness(): Promise<PartnerBusinessResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/businessUnit`;
    return await this.http.get(url);
  }
}

export const partnerController = new PartnerController({ url: env.httpUrl });
