import { memo, useMemo } from "react";
import { IconTypes } from "../../../../enums";
import { useTranslation } from "../../../../hooks";
import { BetShopRoundResult } from "../../../../models";

import { T } from "../../../../constants";
import { GameIds } from "../../../../constants/games";

import Racing from "./racing";
import OddBall from "./odd-ball";
import Fairness from "../fairness";
import DoubleWheel from "./double-wheel";
import GameResultSection from "./game-result-section";
import GameHomeAway from "./game-home-away";
import Roulette from "./spin-to-win";
import Keno from "./keno";

interface ResultsProps {
  betItemResultData: BetShopRoundResult;
  handleFairnessPopUpClick: () => void;
  setBetItemResultData: (betResult: BetShopRoundResult | null) => void;
}

const Results: React.FC<ResultsProps> = ({
  betItemResultData,
  setBetItemResultData,
  handleFairnessPopUpClick,
}) => {
  const t = useTranslation();

  const { hash, key, salt } = betItemResultData;

  const getResultByGame = useMemo(() => {
    const { gameId, eventName, result } = betItemResultData;

    if (!result) return;

    switch (gameId) {
      case GameIds.VirtualFootball:
      case GameIds.VirtualDragRacing:
      case GameIds.VirtualPenaltyKicks:
        return <GameHomeAway eventName={eventName} result={result} />;
        break;
      case GameIds.Roulette:
      case GameIds.VirtualSpinToWin:
      case GameIds.VirtualAutoRoulette:
      case GameIds.VirtualSpinToWinDeluxe:
        return <Roulette gameId={gameId} result={result} />;
        break;
      case GameIds.OddBall:
        return <OddBall result={result} />;
        break;
      case GameIds.Keno:
        return <Keno result={result} />;
        break;
      case GameIds.DoubleWheel:
        return <DoubleWheel result={result} />;
        break;
      case GameIds.VirtualDragRacing:
      case GameIds.VirtualCycleRacing:
      case GameIds.VirtualHorseRacing:
      case GameIds.VirtualMarbleRacing:
      case GameIds.VirtualGreyhoundRacing:
      case GameIds.VirtualGreyhound8Racing:
      case GameIds.VirtualPrerecorded10Harness:
        return <Racing gameId={gameId} result={result?.split(" ")} />;
        break;
    }
  }, [betItemResultData]);

  return (
    <div className="qrMainHolderResults">
      <div className="qrResultHeader">
        <div
          className="resultHeaderBack"
          onClick={() => setBetItemResultData(null)}
        >
          <i className={`${IconTypes.ArrowLeft} iconResultBack`} />
        </div>
        <div className="resultHeaderTxtContainer">
          <span className="resultHeaderTxt">{t(T.RESULT)}</span>
        </div>
        <div
          className="qrBetsHeaderBtnContainer"
          onClick={handleFairnessPopUpClick}
        >
          <i className="icon-fairnessIcon qrBetsHeaderBtnIcon big" />
        </div>
      </div>

      <GameResultSection
        className={
          betItemResultData.gameId === GameIds.VirtualSpinToWinDeluxe ||
          betItemResultData.gameId === GameIds.VirtualSpinToWin
            ? "withPadding"
            : ""
        }
      >
        {getResultByGame}
      </GameResultSection>

      <Fairness hash={hash} fairnessKey={key} salt={salt} />
    </div>
  );
};

export default memo(Results);
