import React from "react";

import { useTranslation } from "../../../../hooks";
import { IconTypes } from "../../../../enums";
import { Icon } from "../icon";

import LoadingCircle from "../../../../assets/images/loading-circle.png";

type ButtonType = "submit" | "reset" | "button" | undefined;

interface ButtonProps {
  icon?: IconTypes;
  loading?: boolean;
  type?: ButtonType;
  className?: string;
  disabled?: boolean;
  text?: string | number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: React.FC<ButtonProps> = ({
  icon,
  onClick,
  text = "",
  className = "",
  type = "button",
  loading = false,
  disabled = false,
}) => {
  const translate = useTranslation();

  return (
    <button
      type={type}
      onClick={onClick}
      className={`common-button ${className} ${disabled || loading ? "disabled" : ""}`}
    >
      {loading ? (
        <div className="loading-wr">
          <img
            src={LoadingCircle}
            alt="loading-circle"
            className="btn-loader"
          />
        </div>
      ) : (
        <>
          {translate(`${text}`)}
          {icon && <Icon type={icon} />}
        </>
      )}
    </button>
  );
};
