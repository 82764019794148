import { makeObservable, observable, action } from "mobx";

import { HttpResponse, userController } from "../api";
import { UserWallet } from "../models";
import { RootStore } from ".";

export class UserStore {
  private rootStore?: RootStore;

  public managerId: null | number = null;

  public wallet = new HttpResponse<UserWallet>({
    balance: 0,
    limit: 0,
    managerId: 0,
  });

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      managerId: observable,
      wallet: observable,
      setWallet: action.bound,
    });
  }

  public setWallet(value: HttpResponse<UserWallet>): void {
    this.wallet = value;
  }

  public updateWallet(value: UserWallet): void {
    const updatedWallet = this.wallet.fetched(value);
    this.setWallet(updatedWallet);
  }

  public async getWallet(): Promise<void> {
    this.setWallet(this.wallet.fetching());
    const data = await userController.getWallet();
    this.managerId = data.managerId;
    this.setWallet(this.wallet.fetched(data));
  }
}
