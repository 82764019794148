import React, { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";

import { LocalStorageKeys, ThemeReverseMap } from "../../../../../../constants";
import { getCurrentTheme, LocalStorageHelper } from "../../../../../../helpers";
import { IconTypes, Theme as ThemeType } from "../../../../../../enums";
import { useStore } from "../../../../../../hooks";
import { Icon } from "../../../../shared";

interface ThemeProps {}

export const Theme: React.FC<ThemeProps> = observer(() => {
  const { iFrameStore } = useStore();

  const [theme, setTheme] = useState<ThemeType>(getCurrentTheme());

  const changeTheme = useCallback(() => {
    const newTheme = ThemeReverseMap[theme];
    // send theme change to provider frame
    iFrameStore.notifyThemeChange(newTheme);
    // set theme for current application
    setTheme(newTheme);
    LocalStorageHelper.setItem(LocalStorageKeys.ViewMode, newTheme);
    document.documentElement.setAttribute("class", newTheme);
  }, [theme]);

  return (
    <div className="light-dark-mode-container" onClick={changeTheme}>
      <Icon
        type={theme === ThemeType.Light ? IconTypes.LightMode : IconTypes.Moon}
      />
    </div>
  );
});
