import React, { useRef, MutableRefObject, useEffect, useCallback } from "react";

import { useOutsideClick } from "../../../../../hooks";
import { DatePickerDay } from "../../../../../models";
import { Navigation } from "../navigation";
import { Weeks } from "../weeks";
import { Days } from "../days";
import { Icon } from "../../icon";
import { IconTypes } from "../../../../../enums";
import { Button } from "../../button";
import { Input } from "../../input";
import { RegexConstants, T } from "../../../../../constants";
import { getHours, getMinute } from "../../../../../helpers/data-picker-hours";

interface CalendarProps {
  min?: Date;
  max?: Date;
  year: number;
  hideFutureDays?: boolean;
  month: number;
  onClose: () => void;
  value: Date | string;
  selectedHours: string;
  selectedMinute: string;
  days: Array<DatePickerDay>;
  onChange: (day: DatePickerDay) => void;
  onYearNavClick: (step: -1 | 1) => void;
  onMonthNavClick: (step: -1 | 1) => void;
  openerRef: MutableRefObject<HTMLDivElement | null>;
  setSelectedHours: (date: any) => void;
  setSelectedMinute: (date: any) => void;
}

export const Calendar: React.FC<CalendarProps> = ({
  min,
  max,
  year,
  month,
  days,
  value,
  onClose,
  onChange,
  openerRef,
  onYearNavClick,
  onMonthNavClick,
  selectedHours,
  selectedMinute,
  setSelectedHours,
  setSelectedMinute,
  hideFutureDays,
}) => {
  const focusMinuteInputRef = useRef<HTMLInputElement>(null);
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(onClose, datePickerRef, openerRef);

  const handleTimeChange = (
    value: string,
    callback: React.SetStateAction<any>,
    type: string,
  ) => {
    if (!value) {
      callback(value);
    } else if (
      (value[0] === "0" || value[0] === "1" || value[0] === "2") &&
      type === "hours" &&
      RegexConstants.Hours.test(value)
    ) {
      callback(value);
      if (focusMinuteInputRef.current && value.length === 2) {
        focusMinuteInputRef.current.focus();
      }
    } else if (type === "minute" && RegexConstants.Minute.test(value)) {
      callback(value);
    }
  };

  const incrementOrDecrementHours = (action: string) => {
    setSelectedHours(getHours(selectedHours, action));
  };

  const incrementOrDecrementMinutes = (action: string) => {
    setSelectedMinute(getMinute(selectedMinute, action));
  };

  return (
    <div ref={datePickerRef} className="date-picker top-filter">
      <div className="date-picker-inner">
        <Navigation
          year={year}
          month={month}
          onYearNavClick={onYearNavClick}
          onMonthNavClick={onMonthNavClick}
        />
        <Weeks />
        <Days
          hideFutureDays={hideFutureDays}
          days={days}
          selectedDay={value}
          onDayClick={onChange}
        />
        {/*  Date Picker Hours Block*/}
        <div className="date-picker-hours">
          <div className="date-picker-hours-col">
            <button
              type="button"
              className="date-picker-btn increment-btn"
              onClick={() => incrementOrDecrementHours("plus")}
            >
              <i className="icon-left-stroke " />
            </button>
            <input
              type="text"
              className="date-picker-hours-input"
              value={selectedHours}
              onChange={(e) =>
                handleTimeChange(e.target.value, setSelectedHours, "hours")
              }
              placeholder="00"
            />
            <button
              type="button"
              className="date-picker-btn decrement-btn"
              onClick={() => incrementOrDecrementHours("minus")}
            >
              <i className="icon-left-stroke " />
            </button>
          </div>
          <div className="date-picker-hours-col">
            <button
              type="button"
              className="date-picker-btn increment-btn"
              onClick={() => incrementOrDecrementMinutes("plus")}
            >
              <i className="icon-left-stroke " />
            </button>
            <input
              type="text"
              className="date-picker-hours-input"
              value={selectedMinute}
              onChange={(e) =>
                handleTimeChange(e.target.value, setSelectedMinute, "minute")
              }
              ref={focusMinuteInputRef}
              placeholder="00"
            />
            <button
              type="button"
              className="date-picker-btn decrement-btn"
              onClick={() => incrementOrDecrementMinutes("minus")}
            >
              <i className="icon-left-stroke " />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
