import React, { forwardRef } from 'react';

import { IconTypes } from '../../../../../../../enums';
import { env } from '../../../../../../../environment';
import { Icon } from '../../../../../shared';

interface SelectedLanguageProps {
  label: string;
  flag: string | null;
  onClick: () => void;
}

export const SelectedLanguage = forwardRef<null | HTMLDivElement, SelectedLanguageProps>(({
  flag,
  label,
  onClick
}, ref) => {
  return (
    <div
      ref={ref}
      onClick={onClick}
      className="select-country-container"
    >
      <div className="country-flag-container">
        {
          flag && (
            <img src={`${env.httpUrl}/translationService/${flag}`} alt={label} />
          )
        }
      </div>
      <span className="country-name-text">{label}</span>
      <div className="user-arrow-icon-container">
        <Icon type={IconTypes.Arrow} />
      </div>
    </div>
  );
});
