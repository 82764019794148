import React from "react";

import { KeyValue } from "../../ticket/key-value";
import { Line } from "../../ticket/line";
import { T } from "../../../../../../constants";

interface WinTicketContentFooterProps {
  totalWin: number;
  totalOdd: number;
  isExpress: boolean;
  showTotalWinAmount: boolean;
  currencyCode: string;
  totalBetAmount: number;
}

export const WinTicketContentFooter: React.FC<WinTicketContentFooterProps> = ({
  totalWin,
  totalOdd,
  isExpress,
  currencyCode,
  totalBetAmount,
  showTotalWinAmount,
}) => {
  return (
    <>
      <Line type="solid" />
      <div style={{ marginTop: "10mm" }}>
        {isExpress && <KeyValue label={T.TOTAL_ODDS} value={`x${totalOdd}`} />}
        <KeyValue
          label={T.TOTAL_BET}
          value={`${totalBetAmount} ${currencyCode}`}
        />
        {showTotalWinAmount ? (
          <KeyValue
            label={T.TOTAL_WIN_AMOUNT}
            value={`${totalWin} ${currencyCode}`}
          />
        ) : null}
      </div>
    </>
  );
};
