import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {formatDate, getMonthDays, utcToLocal} from '../../../../helpers';
import {DATE_FORMAT_BACK} from '../../../../constants';
import {DatePickerInput} from './date-picker-input';
import {DatePickerDay} from '../../../../models';
import {Calendar} from './calendar';

interface DatePickerProps {
	min?: Date;
	max?: Date;
	value: Date | string;
	placeholder?: string;
	time?: string;
	hideFutureDays?: boolean;
	onChange: (date: Date) => void;
	changeHours: (value: string) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
																												min,
																												max,
																												value = '',
																												onChange,
																												placeholder,
																												time,
																												changeHours,
																												hideFutureDays
																											}) => {

	const datePickerInputRef = useRef<HTMLDivElement | null>(null);
	const [selectedHours, setSelectedHours] = useState('');
	const [selectedMinute, setSelectedMinute] = useState('');

	const localDate = useMemo(() => {
		return value ? new Date(utcToLocal(value, DATE_FORMAT_BACK)) : '';
	}, [value, time])

	const initialData = useMemo(() => {
		const selectedOrToday = localDate ? new Date(localDate) : new Date();
		const thisMonth = selectedOrToday.getMonth();
		const thisYear = selectedOrToday.getFullYear();
		return {thisMonth, thisYear};
	}, [localDate]);

	const [month, setMonth] = useState(initialData.thisMonth);
	const [year, setYear] = useState(initialData.thisYear);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!open || !time) {
			if (selectedHours.length < 2 || selectedMinute.length < 2 || !time) {
				setSelectedHours('')
				setSelectedMinute('')
				changeHours('')
			}
		}
	}, [open, time])

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const days = useMemo(() => {
		return getMonthDays(year, month);
	}, [month, year]);

	const onYearNavClick = useCallback((value: number) => {
		setYear((prev) => prev + value);
	}, []);

	const onMonthNavClick = useCallback((value: number) => {
		const newMonth = (month + value + 12) % 12;
		setMonth(newMonth);
		if (
			(newMonth === 0 && month === 11) ||
			(newMonth === 11 && month === 0)
		) {
			// year change
			setYear((prev) => prev + value);
		}
	}, [month]);

	const handleChange = useCallback((day: DatePickerDay) => {
		// if (day.disabled) return;
		setOpen(false);
		onChange(day.date);
	}, [onChange]);

	useEffect(() => {
		if (open) {
			changeHours(`${selectedHours}:${selectedMinute}`)
		}
	}, [selectedHours, selectedMinute])

	return (
		<div className={`top-filter-tabs-input-date ${open ? 'active' : ''}`}>
			<DatePickerInput
				ref={datePickerInputRef}
				placeholder={placeholder}
				value={localDate ? `${formatDate(value)} ${time || ''}` : ''}
				onClick={() => setOpen((prev) => !prev)}
			/>
			<Calendar
				min={min}
				max={max}
				year={year}
				days={days}
				month={month}
				value={value}
				onClose={handleClose}
				onChange={handleChange}
				openerRef={datePickerInputRef}
				onYearNavClick={onYearNavClick}
				onMonthNavClick={onMonthNavClick}
				setSelectedHours={setSelectedHours}
				setSelectedMinute={setSelectedMinute}
				selectedHours={selectedHours}
				selectedMinute={selectedMinute}
				hideFutureDays={hideFutureDays}
			/>
		</div>
	);
};
