import { useCallback } from "react";

import { RegexConstants, T } from "../../../../../constants";
import { useTranslation } from "../../../../../hooks";
import { Button, Input } from "../../../shared";

interface CheckTicketFormProps {
  barcode?: string;
  loading: boolean;
  errorMessage?: string;
  onCheckClick: () => void;
  onLastTicketClick: () => void;
  isPinRequiredForPayout: boolean;
  setBarrCodeErrorMessage: (s: string) => void;
  onBarcodeChange: (barcode: string) => void;
}

const barcodeLength = 13;

export const CheckTicketForm: React.FC<CheckTicketFormProps> = ({
  barcode = "",
  onCheckClick,
  setBarrCodeErrorMessage,
  loading = false,
  onBarcodeChange,
  onLastTicketClick,
  errorMessage = "",
}) => {
  const t = useTranslation();

  const barcodeDisabled = barcode.length !== barcodeLength || loading;

  const handleBarcodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setBarrCodeErrorMessage("");
      if (value.length > barcodeLength) return;
      onBarcodeChange(value);
    },
    [onBarcodeChange],
  );

  return (
    <>
      <div className="wrapper-pp">
        <div className={`input-block-popup ${errorMessage ? "error" : ""}`}>
          <p className="input-text">{t(T.BARCODE)}</p>
          <Input
            value={+barcode || ""}
            className="input-digit-code"
            regEx={RegexConstants.Number}
            onChange={handleBarcodeChange}
            placeholder={T.ENTER_THE_BARCODE}
            autoFocus
          />
          <p className="error-text">{t(errorMessage)}</p>
        </div>
      </div>
      <div className="bet-button-block">
        <Button
          disabled={loading}
          text={T.LAST_TICKET}
          className="pp-button for-dis"
          onClick={onLastTicketClick}
        />
        <Button
          text={T.CHECK}
          onClick={onCheckClick}
          disabled={barcodeDisabled}
          className="pp-button active"
        />
      </div>
    </>
  );
};
