import React, { useCallback, useState } from "react";

import { useStore, useTranslation } from "../../../../hooks";
import { Button, Input } from "../../../components/shared";
import { LoginRequest } from "../../../../models";
import { Toaster } from "../../../containers";
import { T } from "../../../../constants";

interface LoginFormProps {}

export const LoginForm: React.FC<LoginFormProps> = () => {
  const { authStore } = useStore();
  const t = useTranslation();

  const [credentials, setCredentials] = useState<LoginRequest>({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleCredentialsChange = useCallback(
    (field: keyof LoginRequest, value: string) => {
      setCredentials((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [],
  );

  const loginDisabled =
    loading || !credentials.username || !credentials.password;

  const handleSubmit = useCallback(
    (event: React.FormEvent): void => {
      event.preventDefault();
      if (loginDisabled) return;
      setLoading(true);
      const { username, password } = credentials;
      authStore
        .login({
          username: username.trim(),
          password: password.trim(),
        })
        .then((data) => {
          authStore.setIsAuth(true);
        })
        .catch((error) => {
          Toaster.error({ message: error?.errorMessage });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [loginDisabled, credentials],
  );

  return (
    <div className="cashier-login-right-side">
      <form className="login-sign-in-container" onSubmit={handleSubmit}>
        <span className="sign-in-text">Sign In To Your Account</span>
        <div className="login-page-input-container">
          <div className="login-page-field-group">
            <Input
              placeholder={t(T.USERNAME)}
              className="login-page-input"
              value={credentials.username}
              onChange={(e) =>
                handleCredentialsChange("username", e.target.value)
              }
            />
            <Input
              type="password"
              placeholder={t(T.PASSWORD)}
              className="login-page-input"
              value={credentials.password}
              onChange={(e) =>
                handleCredentialsChange("password", e.target.value)
              }
            />
          </div>

          {/*todo span below is forgot password text*/}

          {/*<span className="forgot-password">*/}
          {/*  forgot password?*/}
          {/*</span>*/}
          <Button
            type="submit"
            text={T.SIGN_IN}
            loading={loading}
            className={`login-page-button ${loginDisabled ? "disabled" : ""}`}
          />
        </div>

        {/*  todo forgot-password part start */}
        <div className="reset-password-wrapper">
          <span className="forgot-password-title">forgot password</span>
          <span className="forgot-password-txt">
            Enter your email to receive a secure password reset link.
          </span>
          <input
            type="email"
            className="login-page-input email-input"
            placeholder="Email"
          />
          <button
            type="submit"
            className="common-button login-page-button disabled margin"
          >
            Reset Password
          </button>
          <div className="back-to-sign-container">
            <button type="submit" className="back-to-sign-btn disabled">
              <div className="back-to-sign-icon-holder">
                <i className="icon-left-stroke back-to-sign-icon" />
              </div>
              <span className="reset-password-txt">back to sign in</span>
            </button>
          </div>
        </div>
        {/*  todo forgot-password part end */}

        {/*  todo forgot-password-successfull part start */}
        <div className="email-sent-wrapper">
          <div className="email-sent-title-ctn">
            <span className="forgot-password-title no-margin">
              email sent successfully
            </span>
            <div className="email-sent-icon-ctn">
              <i className="icon-round-done email-done-icon" />
            </div>
          </div>
          <div className="email-sent-texts-ctn">
            <span className="email-sent-text">
              Your request has been sent. Please check your email.
            </span>
            <span className="email-sent-text">
              If you don't receive an email within the next few minutes, please
              check your spam folder.
            </span>
          </div>
          <button type="submit" className="back-to-sign-btn disabled">
            <span className="reset-password-txt">BACK TO SIGN</span>
          </button>
        </div>
        {/*  todo forgot-password-successfull part end */}
      </form>
    </div>
  );
};
