import { TicketAccepted, UserWallet } from "../models";
import { BaseLiveConnection } from "../services";
import { SocketEventTypes } from "../enums";
import { env } from "../environment";

class LiveConnection extends BaseLiveConnection {
  public onWalletUpdate(handler: (data: UserWallet) => void): void {
    this.on(SocketEventTypes.WalletUpdate, handler);
  }

  public onTicketAccepted(handler: (data: TicketAccepted) => void): void {
    this.on(SocketEventTypes.TicketAccepted, handler);
  }
}

export const ws = new LiveConnection(env.wsUrl);
