import React from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { RoutePaths } from '../../../constants';
import Login from '../../../view/pages/login';
import { useStore } from '../../../hooks';

interface IsNotAuthLayerProps {

}

export const IsNotAuthLayer: React.FC<IsNotAuthLayerProps> = observer(() => {

  const { authStore: { isAuth } } = useStore();
  
  return (
    isAuth ? <Navigate to={RoutePaths.Game} /> : <Login />
  );
});
