import { BaseApiService } from "../../base-api-service";
import { ProviderGamesResponse, ProvidersResponse } from "../../../models";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";

class ProvidersController extends BaseApiService {
  public async getProviders(): Promise<ProvidersResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/providers`;
    return await this.http.get(url);
  }

  public async getProviderGames(id: number): Promise<ProviderGamesResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/providers/${id}/games`;
    return await this.http.get(url);
  }
}

export const providersController = new ProvidersController({
  url: env.httpUrl,
});
