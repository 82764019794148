import React from "react";

interface GameHomeAwayProps {
  eventName?: string;
  result: string;
}
const GameHomeAway: React.FC<GameHomeAwayProps> = ({ eventName, result }) => {
  return (
    <>
      <span className="qrResultGameAboutTxt txtSizeMedium2 txtColorYellow">
        {eventName}
      </span>
      <span className="qrResultGameAboutTxt txtColorWhite txtMediumBolder">
        {result}
      </span>
    </>
  );
};

export default GameHomeAway;
