import React from "react";

import { IconTypes } from "../../../../../enums";
import { Icon } from "../../icon";

interface PaginationArrowProps {
  icon: IconTypes;
  disabled: boolean;
  onClick: () => void;
}

export const PaginationArrow: React.FC<PaginationArrowProps> = ({
  icon,
  onClick,
  disabled,
}) => {
  return (
    <div onClick={onClick} className={`page-btn ${disabled ? "disabled" : ""}`}>
      <Icon type={icon} />
    </div>
  );
};
