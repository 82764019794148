import { memo, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery, useStore } from "../../../hooks";

import { Loader } from "../../components/shared";
import { BetShopRoundResult } from "../../../models";
import Results from "../../components/ticket-details/results";
import BetSection from "../../components/ticket-details/bet-items-section";
import FairnessPopUp from "../../components/ticket-details/fairness-pop-up";
import NotFound from "../not-found";
import { NOT_AVAILABLE_GAMES_FAIRNESS } from "../../../constants/games";

const QRTicketDetails = observer(() => {
  const { query } = useQuery();

  const { ticketsStore } = useStore();
  const {
    ticketDetails: {
      data: { result },
    },
  } = ticketsStore;

  const [openFairnessPopUp, setOpenFairnessPopUp] = useState<boolean>(false);
  const [betItemResultData, setBetItemResultData] =
    useState<BetShopRoundResult | null>();

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (query.betId && query.gameId) {
      ticketsStore
        .getTicketDetails({
          betId: query.betId,
          gameId: query.gameId,
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, []);

  const handleFairnessPopUpClick = useCallback(() => {
    setOpenFairnessPopUp(!openFairnessPopUp);
  }, [openFairnessPopUp]);

  const openResultSection = useCallback((data: BetShopRoundResult) => {
    setBetItemResultData(data);
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : NOT_AVAILABLE_GAMES_FAIRNESS.includes(+query.gameId) || !result ? (
        <NotFound hideBackToLink />
      ) : (
        <>
          {openFairnessPopUp ? (
            <FairnessPopUp
              handleFairnessPopUpClick={handleFairnessPopUpClick}
            />
          ) : null}

          {betItemResultData ? (
            <Results
              betItemResultData={betItemResultData}
              setBetItemResultData={setBetItemResultData}
              handleFairnessPopUpClick={handleFairnessPopUpClick}
            />
          ) : (
            <BetSection
              data={result}
              openResultSection={openResultSection}
              handleFairnessPopUpClick={handleFairnessPopUpClick}
            />
          )}
        </>
      )}
    </>
  );
});

export default memo(QRTicketDetails);
