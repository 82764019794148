import React, { MutableRefObject } from "react";

import { ModalHeader } from "./modal-header";

interface ModalStructureProps {
  title?: string;
  width?: number;
  onClose: () => void;
  children: React.ReactNode;
  modalRef: MutableRefObject<HTMLDivElement | null>;
}

export const ModalStructure: React.FC<ModalStructureProps> = ({
  title = "",
  width,
  onClose,
  children,
  modalRef,
}) => {
  return (
    <div className="popup-holder">
      <div className="popup-inner-wrapper">
        <div
          ref={modalRef}
          className="popup-inner"
          style={{ width: `${width}rem` }}
        >
          <ModalHeader title={title} onClose={onClose} />
          {children}
        </div>
      </div>
    </div>
  );
};
