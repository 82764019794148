import React from "react";
import { IconTypes } from "../../../../../../../enums";

import { useTranslation } from "../../../../../../../hooks";
import { Icon } from "../../../../../shared";

interface UserActionProps {
  name: string;
  icon: IconTypes;
  onClick: () => void;
}

export const UserAction: React.FC<UserActionProps> = ({
  name,
  icon,
  onClick,
}) => {
  const t = useTranslation();

  return (
    <div className="drop-country-row" onClick={onClick}>
      <div className="account-drop-icon-container">
        <Icon type={icon} className="user-drop-icon" />
      </div>
      <span className="account-dropdown-text">{t(name)}</span>
    </div>
  );
};
