import { observer } from 'mobx-react-lite';
import React from 'react';

import { formatNumber } from '../../../../helpers';
import { useStore } from '../../../../hooks';

interface CurrencyProps {
  value: number;
  showBalanceLimit?: any;
  isBalance?: boolean;
  withMoneyIcons?: boolean;
}

export const Currency: React.FC<CurrencyProps> = observer(({
                                                             showBalanceLimit,
                                                             withMoneyIcons,
                                                             value,
                                                             isBalance,

                                                           }) => {

  const { authStore: { currencyCode } } = useStore();

  const amount = `${formatNumber(value)} ${currencyCode}`;

  function maskString(input: any) {
    if (typeof input !== 'string') {
      return 'Invalid input';
    }
    return input.replace(/[^\s]/g, '*').replace(/ /g, '*');
  }

  return (
    <span
      title={amount}>{withMoneyIcons ? (isBalance ? showBalanceLimit.isShowBalance : showBalanceLimit.isShowLimit) ? amount : maskString(amount) : amount}</span>
  );
});
