import { LoginRequest, LoginResponse, HTTPResponse } from "./../../../models";
import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";

class AuthController extends BaseApiService {
  public async login(data: LoginRequest): Promise<LoginResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/token`;
    return await this.http.post(url, data);
  }

  public async refresh(): Promise<LoginResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/token/refresh`;
    return await this.http.post(url);
  }

  public async logout(): Promise<void> {
    const url = `${this.url}/api/${ApiVersions.V1}/token/logout`;
    return await this.http.post(url);
  }

  public async getProviderToken(providerId: number): Promise<string> {
    const url = `${this.url}/api/${ApiVersions.V1}/token/otp`;
    return await this.http.post(url, { providerId });
  }
}

export const authController = new AuthController({ url: env.httpUrl });
