import React from "react";

import { useTranslation } from "../../../../../../hooks";

interface TableHeaderTopProps {
  title: string;
  actions?: React.ReactNode;
}

export const TableHeaderTop: React.FC<TableHeaderTopProps> = ({
  title,
  actions,
}) => {
  const t = useTranslation();

  return (
    <div className="top-tabs-section">
      <div className="top-tabs-content-container forTransactions">
        <div

            //   todo this active class is for TRANSACTIONS


            className={`active tickets-tab-container ${actions ? "show-divider" : ""}`}
        >
          <span className="tabs-inner-text">{t(title)}</span>
        </div>
        {actions}
      </div>
    </div>
  );
};
