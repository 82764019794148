import React from "react";

import { NotificationItem } from "./notification-item";
import { Notification } from "../../../../models";

interface NotificationListProps {
  data: Array<Notification>;
  onClose: (id: string) => void;
}

export const NotificationList: React.FC<NotificationListProps> = ({
  data,
  onClose,
}) => {
  return (
    <ul className="notification-list">
      {data.map(({ id, icon, type, message }) => (
        <NotificationItem
          id={id}
          key={id}
          icon={icon}
          type={type}
          message={message}
          onClose={onClose}
        />
      ))}
    </ul>
  );
};
