import { useCallback } from "react";
import { REGEX_NUMERIC } from "../constants";

export function useAmountValidation(
  handleBetSectionsState,
  regex = REGEX_NUMERIC,
) {
  const onChange = useCallback(
    (value) => {
      if (value.length > 1 && !value.includes(".") && value[0] === "0")
        value = value.slice(1);

      value = String(value.trim());
      if (regex.test(value) || value === "")
        return handleBetSectionsState(value);

      return null;
    },
    [handleBetSectionsState, regex],
  );

  return { onChange };
}
