import React from 'react';

import { useTranslation } from '../../../../../hooks';

interface TicketResultKeyValueProps {
  label: string;
  value: React.ReactNode;
  children?: React.ReactNode;
}

const TicketResultKeyValue: React.FC<TicketResultKeyValueProps> = ({ children, label, value }) => {

  const t = useTranslation();

  return (
    <div className="date-time-liner">
      <div className="data-cont">
       <span className="ticket-data-gray">
        {t(label)}:
       </span>
       <span className="ticket-data">
        {value}
       </span>
      </div>
      {children}
    </div>
  );
};

export default TicketResultKeyValue;
