import React, { useCallback, useEffect, useState } from 'react';

import { AlertNotification, Notification } from '../../../models';
import { IconTypes, NotificationTypes } from '../../../enums';
import { NotificationList } from '../../components/shared';
import { guid } from '../../../helpers';

interface ToasterProps {

}

interface ToasterType extends React.FC<ToasterProps> {
  success: (note: AlertNotification) => void;
  error: (note: AlertNotification) => void;
  removeAll: () => void;
}

export const Toaster: ToasterType = () => {

  const [ notifications, setNotifications ] = useState<Array<Notification>>([]);

  useEffect(() => {
    Toaster.success = (note: AlertNotification): void => {
      alert({
        ...note,
        id: guid(),
        type: NotificationTypes.Success,
        icon: note.icon || IconTypes.DoneStroke
      });
    };
    Toaster.error = (note: AlertNotification): void => {
      alert({
        ...note,
        id: guid(),
        type: NotificationTypes.Error,
        icon: note.icon || IconTypes.Info
      });
    };
    Toaster.removeAll = (): void => {
      setNotifications([]);
    };
  }, []);

  const remove = useCallback((selectedNoteId: string) => {
    setNotifications((prev) => (
      prev.filter(({ id }) => id !== selectedNoteId)
    ));
  }, []);

  const removeAfterTimeout = useCallback((selectedNoteId: string, duration = 4000) => {
    const timeoutId = setTimeout(() => {
      remove(selectedNoteId);
      clearTimeout(timeoutId);
    }, duration);
  }, [remove]);

  const alert = useCallback((note: Notification) => {
    const { id, duration } = note;
    removeAfterTimeout(id, duration);
    setNotifications((prev) => [ ...prev, note ]);
  }, [setNotifications, removeAfterTimeout]);

  return (
    <NotificationList
      onClose={remove}
      data={notifications}
    />
  );
};

// for typechecking
Toaster.success = () => {};
Toaster.error = () => {};
Toaster.removeAll = () => {};

// setTimeout(() => {
//   // example of usage
//   Toaster.error({ message: 'error message' });
//   Toaster.success({ message: 'success message', icon: IconTypes.SunStroke, duration: 5000 });
// }, 2000);
