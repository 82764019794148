interface FairnessPopUpProps {
  handleFairnessPopUpClick: () => void;
}

const FairnessPopUp: React.FC<FairnessPopUpProps> = ({
  handleFairnessPopUpClick,
}) => {
  return (
    <div className="qrFairnessPopupHolder">
      <div className="qrFairnessPopupHeaderContainer">
        <div
          className="qrqrPopupCloseHolder"
          onClick={handleFairnessPopUpClick}
        >
          <i className="icon-close qrPopupClose" />
        </div>
        <i className="icon-fairnessIcon qrBetsHeaderBtnIcon biggest" />
        <span className="qrFairnessPopupHeaderTxt">Provable Fairness</span>
      </div>
      <div className="qrFairnessPopupMainTxtContainer">
        <span className="qrFairnessPopupMainTxt">
          The game uses 1 traditional deck of 52 playing cards. The cards are
          shuffled by a licensed RNG system and placed in a Shoe, after which
          the players are displayed the order of the cards in the Shoe in form
          of a Hash code.
        </span>
        <span className="qrFairnessPopupMainTxt">
          The game uses 1 traditional deck of 52 playing cards. The cards are
          shuffled by a licensed RNG system and placed in a Shoe, after which
          the players are displayed the order of the cards in the Shoe in form
          of a Hash code.
        </span>
        <span className="qrFairnessPopupMainTxt">
          The game uses 1 traditional deck of 52 playing cards. The cards are
          shuffled by a licensed RNG system and placed in a Shoe, after which
          the players are displayed the order of the cards in the Shoe in form
          of a Hash code.
        </span>
        <span className="qrFairnessPopupMainTxt">
          The game uses 1 traditional deck of 52 playing cards. The cards are
          shuffled by a licensed RNG system and placed in a Shoe, after which
          the players are displayed the order of the cards in the Shoe in form
          of a Hash code.
        </span>
        <span className="qrFairnessPopupMainTxt">
          The game uses 1 traditional deck of 52 playing cards. The cards are
          shuffled by a licensed RNG system and placed in a Shoe, after which
          the players are displayed the order of the cards in the Shoe in form
          of a Hash code.
        </span>
        <span className="qrFairnessPopupMainTxt">
          The game uses 1 traditional deck of 52 playing cards. The cards are
          shuffled by a licensed RNG system and placed in a Shoe, after which
          the players are displayed the order of the cards in the Shoe in form
          of a Hash code.
        </span>
      </div>
    </div>
  );
};

export default FairnessPopUp;
