import React from "react";

import { UserPopover } from "./header-right/user-popover";
import { Navigation } from "./header-left/navigation";
import { Languages } from "./header-right/languages";
import { Wallet } from "./header-right/wallet";
import { Theme } from "./header-right/theme";
import { HeaderRight } from "./header-right";
import { HeaderLeft } from "./header-left";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="header-row-holder">
      <div className="header-wrapper">
        <HeaderLeft>
          <Navigation />
        </HeaderLeft>

        <HeaderRight>
          <Wallet />
          <Languages />
          <Theme />
          <UserPopover />
        </HeaderRight>
      </div>
    </div>
  );
};
