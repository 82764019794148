import React from 'react';

interface TableHeaderFiltersProps {
  filters?: React.ReactNode;
}

export const TableHeaderFilters: React.FC<TableHeaderFiltersProps> = ({
  filters
}) => {
  return (
    <div className="top-filter-section">
      <div className="top-filter-inner">
        { filters }
      </div>
    </div>
  );
};
