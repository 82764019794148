import React, { useCallback, useMemo, useRef, useState } from "react";

import {
  DropdownDataType,
  DropdownValue as DropdownValueType,
  KeyValuePair,
} from "../../../../models";
import { useTranslation } from "../../../../hooks";
import { DropdownValue } from "./dropdown-value";
import { DropdownList } from "./dropdown-list";

export type DirectionValue = "move-up" | "move-down";

interface DropdownProps {
  placeholder?: string;
  value: DropdownValueType;
  direction?: DirectionValue;
  data: Array<DropdownDataType>;
  onChange: (value: DropdownValueType) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  value,
  onChange,
  data = [],
  placeholder = "",
  direction = "move-down",
}) => {
  const [open, setOpen] = useState(false);
  const t = useTranslation();
  const openerRef = useRef<HTMLDivElement | null>(null);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const dataMap = useMemo(() => {
    return data.reduce<KeyValuePair<DropdownDataType>>((acc, curr) => {
      acc[`${curr.value}`] = curr;
      return acc;
    }, {});
  }, [data]);

  return (
    <div className={`top-filter-tabs ${open ? "active" : ""}`}>
      <DropdownValue
        ref={openerRef}
        placeholder={t(placeholder)}
        label={t(dataMap[`${value}`]?.label)}
        onClick={() => setOpen((prev) => !prev)}
      />
      <DropdownList
        data={data}
        value={value}
        onClick={onChange}
        onClose={handleClose}
        openerRef={openerRef}
        direction={direction}
      />
    </div>
  );
};
