import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  parseNumberByPrecision,
  ReplaceVirtualTranslationKey,
  truncWithPrecision,
} from "../../../../../helpers";

import { BetTicketContentFooter } from "./bet-ticket-content-footer";
import { BetTypeNames } from "../../../../../constants";
import { TicketAccepted } from "../../../../../models";
import { BetInfo } from "../ticket/bet-info";
import { Market } from "../ticket/market";
import { Ticket } from "../ticket";
import { useStore, useTranslation } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { TicketConfig } from "../../../../../models/partnerResponse";

interface BetTicketProps {
  isExpress: boolean;
  data: TicketAccepted;
  ticketConfig: TicketConfig;
}

const BetTicket: React.FC<BetTicketProps> = observer(
  ({ data, isExpress, ticketConfig }) => {
    const t = useTranslation();

    const {
      translationsStore: { selectedLanguageCode },
      ticketsStore,
      partnerStore: { isPinRequiredForPayout },
    } = useStore();

    const {
      barcode,
      date,
      ticketInfo,
      betType,
      amount,
      possibleWin,
      ticketId,
      betId,
      gameId,
    } = data;

    const [ticketLoaded, setTicketLoaded] = useState(false);

    useEffect(() => {
      if (ticketLoaded) {
        if (isPinRequiredForPayout) {
          ticketsStore.printTicket(ticketId).then((res) => {
            window.print();
          });
        } else {
          window.print();
        }
      }
    }, [ticketLoaded]);

    const firstMarketData = useMemo(() => {
      const [firstMarket] = ticketInfo;
      return firstMarket;
    }, [ticketInfo]);

    const gameName = firstMarketData?.gameName || "";
    const currencyCode = firstMarketData?.currency || "";
    const roundId = firstMarketData?.roundId;

    const totalBetAmount = useMemo((): number => {
      if (isExpress) {
        return amount;
      }
      const total = ticketInfo.reduce<number>((acc, curr) => {
        return acc + +(curr.betAmount || 0);
      }, 0);
      return parseNumberByPrecision(1, total);
    }, [ticketInfo, isExpress, amount]);

    const totalOdd = useMemo(() => {
      const odd = ticketInfo.reduce<number>((acc, market) => {
        return acc * market.odds;
      }, 1);
      return truncWithPrecision(odd);
    }, [ticketInfo]);

    const handleTicketLoad = useCallback(() => {
      setTicketLoaded(ticketConfig?.showLogo || true);
    }, []);

    const totalPossibleWin = useMemo(
      () =>
        possibleWin ||
        ticketInfo?.reduce(
          (acc, currentValue) => currentValue.possibleWin + acc,
          0,
        ),
      [possibleWin, ticketInfo],
    );

    return (
      <Ticket
        barcode={barcode}
        onTicketLoad={handleTicketLoad}
        showLogo={ticketConfig?.showLogo}
        logo={ticketConfig?.logo}
      >
        {ticketConfig?.shortTicket ? <h1>{gameName}</h1> : null}

        {!ticketConfig?.shortTicket ? (
          <>
            <BetInfo
              date={date}
              barCode={barcode}
              roundId={roundId}
              gameName={gameName}
              ticketId={ticketId}
              isExpress={isExpress}
              betTypeName={BetTypeNames[betType]}
            />
            {ticketInfo.map(
              (
                {
                  betAmount,
                  odds,
                  marketName,
                  currency,
                  gameName,
                  winAmount,
                  possibleWin,
                  translationSymbols,
                  roundId,
                  eventName,
                  numbers,
                },
                index,
              ) => (
                <Market
                  odd={odds}
                  key={marketName}
                  roundId={roundId}
                  gameName={gameName}
                  betAmount={betAmount}
                  isExpress={isExpress}
                  eventName={
                    translationSymbols?.homeTeam
                      ? `${ReplaceVirtualTranslationKey(
                          t(translationSymbols?.homeTeam),
                          translationSymbols,
                          t,
                        )} - ${ReplaceVirtualTranslationKey(
                          t(translationSymbols?.awayTeam),
                          translationSymbols,
                          t,
                        )}`
                      : ""
                  }
                  marketName={
                    numbers ||
                    ReplaceVirtualTranslationKey(
                      t(translationSymbols?.marketName || marketName),
                      translationSymbols,
                      t,
                    )
                  }
                  selectionName={ReplaceVirtualTranslationKey(
                    t(translationSymbols?.selectionName),
                    translationSymbols,
                    t,
                  )}
                  handicap={translationSymbols?.handicap}
                  currencyCode={currency}
                  showLine={index !== ticketInfo.length - 1}
                  winAmount={winAmount}
                  showWinAmount={ticketConfig?.showExpectedWinPerBet}
                  possibleWin={possibleWin}
                  ticketConfig={ticketConfig}
                />
              ),
            )}
          </>
        ) : null}
        <BetTicketContentFooter
          betId={betId}
          gameId={gameId}
          totalOdd={totalOdd}
          selectedLanguageCode={selectedLanguageCode}
          isExpress={isExpress}
          possibleWin={totalPossibleWin}
          currencyCode={currencyCode}
          totalBetAmount={totalBetAmount}
          totalWinAmount={ticketInfo.reduce(
            (accumulator, currentValue) => accumulator + currentValue.winAmount,
            0,
          )}
          ticketConfig={ticketConfig}
        />
      </Ticket>
    );
  },
);

export default memo(BetTicket);
