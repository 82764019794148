export const parseNumberByPrecision = (
  odd: number,
  betAmount: number,
  precision = 2,
): number => {
  const digitsAfterDotAtOdd = odd.toString().split(".")[1]?.length || 0;
  const digitsAfterDotAtBetAmount = betAmount.toString().split(".").length || 0;
  const digitsCountAfterDot = digitsAfterDotAtBetAmount + digitsAfterDotAtOdd;

  let result: number;
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${precision || 2}})?`);

  const numMulti =
    (Math.round(odd * 10 ** digitsCountAfterDot) *
      Math.round(betAmount * 10 ** digitsCountAfterDot)) /
    10 ** (digitsCountAfterDot + digitsCountAfterDot);

  const [firstMatch] = numMulti.toString().match(re) || [];

  result = +(firstMatch || 0);

  return result;
};
