import React from 'react';

interface HeaderLeftProps {
  children: React.ReactNode;
}

export const HeaderLeft: React.FC<HeaderLeftProps> = ({
  children
}) => {
  return (
    <div className="header-left-side">
      { children }
    </div>
  );
};
