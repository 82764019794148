import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { UserActionsPopover } from './user-actions-popover';
import { useStore } from '../../../../../../hooks';
import { Username } from './username';

interface UserPopoverProps {

}

export const UserPopover: React.FC<UserPopoverProps> = observer(() => {

  const { authStore: { access } } = useStore();

  const usernameRef = useRef<HTMLDivElement | null>(null);

  const [ open, setOpen ] = useState(false);

  return (
    <div className={`username-container ${open ? 'active' : ''}`}>
      <Username
        ref={usernameRef}
        username={access?.username || ''}
        onClick={() => setOpen((prev) => !prev)}
      />
      <UserActionsPopover
        openerRef={usernameRef}
        onOutsideClick={() => setOpen(false)}
      />
    </div>
  );
});
