import { TicketStatusNames } from "./bets-ticket-status";
import { TicketStatus } from "../enums";

export const TICKET_STATUS_DROPDOWN_DATA = [
  {
    value: TicketStatus.Won,
    label: TicketStatusNames[TicketStatus.Won],
  },
  {
    value: TicketStatus.Lost,
    label: TicketStatusNames[TicketStatus.Lost],
  },
  {
    value: TicketStatus.Pending,
    label: TicketStatusNames[TicketStatus.Pending],
  },
  {
    value: TicketStatus.Returned,
    label: TicketStatusNames[TicketStatus.Returned],
  },
  {
    value: TicketStatus.Canceled,
    label: TicketStatusNames[TicketStatus.Canceled],
  },
];
