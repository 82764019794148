import {useCallback} from 'react';
import {DatePickerDay} from '../../../../../models';
import moment from 'moment';

interface DaysProps {
	selectedDay: Date | string;
	hideFutureDays?: boolean,
	days: Array<DatePickerDay>;
	onDayClick: (day: DatePickerDay) => void;
}

export const Days: React.FC<DaysProps> = ({
																						hideFutureDays,
																						days,
																						onDayClick,
																						selectedDay = ''
																					}) => {

	const isDayDisabled = (dateByDay: number, dateByMonth: number, dateByYear: number) => {
		if (hideFutureDays) {
			const date = new Date();
			const day = date.getDate()
			const mon = date.getMonth()
			const year = date.getFullYear()

			return dateByYear > year || (dateByMonth > mon && dateByYear >= year) || (dateByMonth === mon && dateByYear === year && dateByDay > day)
		}
	};

	const generateKey = (pre: number) => {
		return `${pre + Math.random() * pre}_${new Date().getTime()}`;
	}

	return (
		<div className="day-numbers-block">
			{
				days.map((day) => {
					const {date} = day;
					const dateByDay = date.getDate();
					const dateByMonth = date.getMonth();
					const dateByYear = date.getFullYear();

					return (
						<div
							key={generateKey(dateByDay)}
							onClick={() => onDayClick(day)}
							className={`day-numbers-items ${isDayDisabled(dateByDay, dateByMonth, dateByYear) ? 'inactive' : ''} ${moment(date).date() === moment(selectedDay).date() && moment(date).month() === moment(selectedDay).month() ? 'selected' : ''}`}
						>
							{dateByDay}
						</div>
					);
				})
			}
		</div>
	);
};
