import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Loader } from "./components/shared";
import { Header } from "./components/global";
import { GlobalModals } from "./containers";
import { RoutePaths } from "../constants";
import { appService } from "../services";
import { MainRoutes } from "../routing";
import Game from "./pages/game";

interface AppProps {}

export const App: React.FC<AppProps> = observer(() => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = useCallback(async () => {
    await appService.init();
    setLoading(false);
  }, []);

  const providerFrameVisible = location.pathname === RoutePaths.Game;

  return (
    <div className="main-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          {/*<QRTicketDetails/>*/}
          <GlobalModals />
          <div className="content-row-holder">
            <div className="content-box">
              {/*<div className="error-message-container">*/}
              {/*  <div className="error-message-image" />*/}
              {/*  <div className="error-message-content-container">*/}
              {/*    <span className="error-message-content">*/}
              {/*      Our engineers are currently performing scheduled maintenance on the Dashboard. Sorry for any inconvenience.*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*  <div className="error-message-time-container">*/}
              {/*    <span className="error-message-time">*/}
              {/*       20:30*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <Game visible={providerFrameVisible} />
              <Suspense fallback="">
                <Routes>
                  {MainRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<route.component />}
                    />
                  ))}
                </Routes>
              </Suspense>
            </div>
          </div>
        </>
      )}
    </div>
  );
});
