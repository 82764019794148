import { makeObservable, observable, action, computed, toJS } from "mobx";

import { getIsAuthDefaultValue, LocalStorageHelper } from "../helpers";
import { LoginRequest, LoginResponse } from "../models";
import { authController, HttpResponse, ws } from "../api";
import { LocalStorageKeys } from "../constants";
import { RootStore } from ".";

export class AuthStore {
  private rootStore?: RootStore;

  private readonly expirationInsurance = 100;
  private refreshTokenTimer?: number;

  public access: LoginResponse | null = null;
  public isAuth: boolean = getIsAuthDefaultValue();
  public providerToken = new HttpResponse("");

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isAuth: observable,
      access: observable,
      providerToken: observable,
      setIsAuth: action.bound,
      setAccess: action.bound,
      setProviderToken: action.bound,
      resetProviderToken: action.bound,
      currencyCode: computed,
    });
  }

  public get currencyCode() {
    return this.access?.currencyCode;
  }

  public setProviderToken(value: HttpResponse<string>): void {
    this.providerToken = value;
  }

  public resetProviderToken(): void {
    this.setProviderToken(new HttpResponse(""));
  }

  public setIsAuth(value: boolean): void {
    this.isAuth = value;
  }

  public setAccess(value: LoginResponse | null): void {
    this.access = value;
  }

  private setAccessToLocalStorage(access: LoginResponse): void {
    const data = {
      ...access,
      expiresIn:
        (access.expiresIn - this.expirationInsurance) * 1000 + Date.now(),
    };
    LocalStorageHelper.setItem(LocalStorageKeys.Access, data);
  }

  private handleTokenArrival(access: LoginResponse): void {
    this.setAccessToLocalStorage(access);
    this.setAccess(access);
    clearTimeout(this.refreshTokenTimer);
    const timer = (access.expiresIn - this.expirationInsurance) * 1000;

    this.refreshTokenTimer = window.setTimeout(() => {
      authController
        .refresh()
        .then((res) => {
          this.handleTokenArrival(res);
        })
        .catch(async () => {
          await this.logout();
        });
    }, timer);
  }

  public async getProviderToken(providerId: number): Promise<void> {
    this.setProviderToken(this.providerToken.fetching());
    const response = await authController.getProviderToken(providerId);
    this.setProviderToken(this.providerToken.fetched(response));
  }

  public async login(data: LoginRequest): Promise<LoginResponse> {
    const response = await authController.login(data);
    this.handleTokenArrival(response);
    return response;
  }

  public async refresh(): Promise<LoginResponse> {
    const response = await authController.refresh();
    this.handleTokenArrival(response);
    return response;
  }

  public async logout(): Promise<void> {
    this.setIsAuth(false);
    this.setAccess(null);
    LocalStorageHelper.removeItem(LocalStorageKeys.Access);
    clearTimeout(this.refreshTokenTimer);
    await authController.logout();
    await ws.stop();
  }
}
