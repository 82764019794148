import { useTranslation } from "../../../../../../hooks";
import { utcToLocal } from "../../../../../../helpers";
import { T } from "../../../../../../constants";
import { KeyValue } from "../key-value";
import { Line } from "../line";

interface BetInfoProps {
  barCode: number;
  roundId: number;
  ticketId: number;
  gameName: string;
  isExpress: boolean;
  betTypeName: string;
  date: string | Date;
}

export const BetInfo: React.FC<BetInfoProps> = ({
  date,
  barCode,
  roundId,
  gameName,
  ticketId,
  isExpress,
  betTypeName,
}) => {
  const t = useTranslation();

  return (
    <>
      <div>
        <KeyValue
          label={t(T.DATE)}
          value={utcToLocal(date, "DD.MM.YYYY H:mm:ss")}
          textBold
        />

        <KeyValue label={t(T.TICKET_ID)} value={ticketId} textBold />

        {+barCode ? (
          <KeyValue label={t(T.BARCODE)} value={barCode} textBold />
        ) : null}

        <KeyValue label={t(T.BET_TYPE)} value={t(betTypeName)} textBold />
      </div>
      <Line type="solid" />
    </>
  );
};
