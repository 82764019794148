import { action, makeObservable, observable, toJS } from "mobx";

import {
  Ticket,
  PrintTypeModel,
  TicketsResponse,
  GetTicketsRequest,
  TicketOverviewResponse,
  GetTicketOverviewRequest,
  TicketDetails,
  TicketsDetailsResponse,
} from "../models";
import {
  DEFAULT_RESPONSE_LIST,
  DEFAULT_TICKET_OVERVIEW_DATA,
} from "../constants";
import { HttpResponse, ticketsController, HttpResponseStatus } from "../api";
import { PrintTypes } from "../enums";
import { RootStore } from "./index";
import { Toaster } from "../view/containers";
import { ManagerResponse } from "../models/managerResponse";

export class TicketsStore {
  private rootStore?: RootStore;

  public tickets = new HttpResponse<TicketsResponse>(DEFAULT_RESPONSE_LIST);

  public ticket = new HttpResponse<Ticket | null>(
    null,
    HttpResponseStatus.Default,
  );

  public ticketPrintData: PrintTypeModel = {
    type: PrintTypes.None,
    data: null,
  };

  public barrCodeErrorMessage: string | undefined = "";

  public payTicketResponse = new HttpResponse<Ticket | null>(
    null,
    HttpResponseStatus.Default,
  );

  public ticketOverview = new HttpResponse<TicketOverviewResponse>(
    DEFAULT_TICKET_OVERVIEW_DATA,
  );

  public updateTicketsFlag = false;

  public ticketDetails = new HttpResponse<ManagerResponse>({
    errorMessage: null,
    result: null,
  });

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      tickets: observable,
      ticket: observable,
      barrCodeErrorMessage: observable,
      ticketOverview: observable,
      ticketDetails: observable,
      updateTicketsFlag: observable,
      setTickets: action.bound,
      setTicket: action.bound,
      setTicketOverview: action.bound,
      ticketPrintData: observable,
      payTicketResponse: observable,
      setTicketPrintData: action.bound,
      setPayTicketResponse: action.bound,
      resetPayTicketResponse: action.bound,
      resetTicketPrintData: action.bound,
      setBarrCodeErrorMessage: action.bound,
      setUpdateTicketsFlag: action.bound,
      setTicketDetails: action.bound,
    });
  }

  public setTicketDetails(value: HttpResponse<ManagerResponse>): void {
    this.ticketDetails = value;
  }

  public setPayTicketResponse(value: HttpResponse<Ticket | null>): void {
    this.payTicketResponse = value;
  }

  public setBarrCodeErrorMessage(message: string) {
    this.barrCodeErrorMessage = message;
  }

  public resetPayTicketResponse(): void {
    this.setPayTicketResponse(
      new HttpResponse(null, HttpResponseStatus.Default),
    );
  }

  public setTickets(value: HttpResponse<TicketsResponse>): void {
    this.tickets = value;
  }

  public setTicket(value: HttpResponse<Ticket | null>): void {
    this.barrCodeErrorMessage = value.error;
    this.ticket = value;
  }

  public setTicketOverview(value: HttpResponse<TicketOverviewResponse>): void {
    this.ticketOverview = value;
  }

  public resetTicketData(): void {
    this.setTicket(new HttpResponse(null, HttpResponseStatus.Default));
  }

  public setUpdateTicketsFlag(): void {
    this.updateTicketsFlag = !this.updateTicketsFlag;
  }

  public setTicketPrintData(value: PrintTypeModel): void {
    this.ticketPrintData = value;
  }

  public resetTicketPrintData(): void {
    this.setTicketPrintData({
      type: PrintTypes.None,
      data: null,
    });
  }

  public async getTickets(params: GetTicketsRequest): Promise<void> {
    this.setTickets(this.tickets.fetching());
    const result = await ticketsController.getTickets(params);
    this.setTickets(this.tickets.fetched(result));
  }

  public async getTicket(barCodeOrTicketId: number): Promise<void> {
    this.setTicket(this.ticket.fetching());
    ticketsController
      .getTicketByBarcode(barCodeOrTicketId)
      .then((res) => {
        this.setTicket(this.ticket.fetched(res));
      })
      .catch((err) => {
        this.setTicket(this.ticket.failed(err.errorMessage));
      });
  }

  public async getLastTicket(): Promise<void> {
    this.setTicket(this.ticket.fetching());
    const result = await ticketsController.getLastTicket();
    this.setTicket(this.ticket.fetched(result));
  }

  public async getTicketOverview(
    params?: GetTicketOverviewRequest,
  ): Promise<void> {
    this.setTicketOverview(this.ticketOverview.fetching());
    const result = await ticketsController.getTicketOverview(params);
    this.setTicketOverview(this.ticketOverview.fetched(result));
  }

  public async payTicket(barCode: number, ticketId: number): Promise<void> {
    this.setPayTicketResponse(this.payTicketResponse.fetching());
    ticketsController
      .payTicket(barCode, ticketId)
      .then((result) => {
        result.barcode = barCode;
        this.setTicketPrintData({
          type: PrintTypes.Win,
          data: result,
        });
        this.setUpdateTicketsFlag(); // for bets table update
        this.resetPayTicketResponse();
        this.rootStore?.modalStore.setCheckModal({ open: false, data: "" });
      })
      .catch((result) => {
        this.setPayTicketResponse(this.payTicketResponse.fetched(result));
        Toaster.error({ message: result?.errorMessage });
      });
  }

  public async returnTicket(barcode: number): Promise<void> {
    await ticketsController.returnTicket(barcode);
  }

  public async printTicket(ticketId: number): Promise<any> {
    return await ticketsController.printTicket(ticketId);
  }

  public async cancelTicket(barcode: number): Promise<any> {
    return ticketsController.cancelTicketByBarcode(barcode);
  }

  public async getTicketDetails(params: TicketDetails): Promise<void> {
    this.setTicketDetails(this.ticketDetails.fetching());
    const result = await ticketsController.getTicketDetails(params);
    this.setTicketDetails(this.ticketDetails.fetched(result));
  }
}
