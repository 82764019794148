import { EnvironmentTypes } from "./enums";
import { Environment } from "./models";

export const STAGE_DOMAIN = "https://gravity-stage.rpd.cloud";
export const STAGE_MEDIA_DOMAIN = "https://gravity-stage.rpd.cloud";
export const LIVE_MEDIA_DOMAIN = "https://pg.vbet.am";

const reactAppEnv = process.env.REACT_APP_ENV as EnvironmentTypes;

const dev: Environment = {
  devMode: true,
  production: false,
  applicationId: 67,
  wsUrl: `${STAGE_DOMAIN}/api/signalr`,
  httpUrl: `${STAGE_DOMAIN}`,
  mediaUrl: `${STAGE_MEDIA_DOMAIN}`,
  translationsHttpUrl: "https://rpd-translations-stage.betcoapps.com",
};

const stage: Environment = {
  devMode: false,
  production: false,
  applicationId: 67,
  wsUrl: `${STAGE_DOMAIN}/api/signalr`,
  httpUrl: `${STAGE_DOMAIN}`,
  mediaUrl: `${STAGE_MEDIA_DOMAIN}`,
  translationsHttpUrl: "https://rpd-translations-stage.betcoapps.com",
};

const live: Environment = {
  devMode: false,
  production: true,
  applicationId: 45,
  wsUrl: `${window.location.origin}/api/signalr`,
  httpUrl: `${window.location.origin}`,
  mediaUrl: `${LIVE_MEDIA_DOMAIN}`,
  translationsHttpUrl: `${window.location.origin}/translations`,
};

const envs = {
  [EnvironmentTypes.Development]: dev,
  [EnvironmentTypes.Staging]: stage,
  [EnvironmentTypes.Production]: live,
};

export const env = envs[reactAppEnv];
