import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { parseQuery, combineQuery } from "../helpers";
import { KeyValuePair } from "../models";

export const useQuery = () => {
  const location = useLocation();

  const [query, setQuery] = useState<KeyValuePair>(parseQuery(location.search));

  useEffect(() => {
    setQuery(parseQuery(location.search));
  }, [location.search]);

  return {
    queryString: combineQuery(query),
    query,
  };
};
