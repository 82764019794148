import React from "react";

import { KeyValue } from "../../ticket/key-value";
import { Line } from "../../ticket/line";
import { T } from "../../../../../../constants";
import { env } from "../../../../../../environment";
import QRCode from "react-qr-code";
import { useTranslation } from "../../../../../../hooks";
import { TicketConfig } from "../../../../../../models/partnerResponse";
import { toFixNumber } from "../../../../../../helpers/to-fix-number";

interface BetTicketContentFooterProps {
  totalOdd: number;
  isExpress: boolean;
  possibleWin: number;
  currencyCode: string;
  totalBetAmount: number;
  totalWinAmount?: number;
  gameId?: number;
  betId?: number;
  selectedLanguageCode: string;
  ticketConfig?: TicketConfig;
}

export const BetTicketContentFooter: React.FC<BetTicketContentFooterProps> = ({
  totalOdd,
  isExpress,
  possibleWin,
  currencyCode,
  totalBetAmount,
  totalWinAmount,
  gameId,
  betId,
  selectedLanguageCode,
  ticketConfig,
}) => {
  const t = useTranslation();

  return (
    <>
      <Line type="solid" />
      <div style={{ marginTop: "5mm" }}>
        {isExpress && (
          <KeyValue label={T.TOTAL_ODDS} value={`x${totalOdd}`} textBold />
        )}
        <div className="total-box">
          <div className="total-box-inner">
            <KeyValue
              label={T.TOTAL_BET}
              value={`${toFixNumber(totalBetAmount, 555)} ${currencyCode}`}
              textBold
            />
            {totalWinAmount ? (
              <KeyValue
                label={T.TOTAL_WIN_AMOUNT}
                value={`${toFixNumber(totalWinAmount)} ${currencyCode}`}
                textBold
              />
            ) : null}
            {ticketConfig?.showExpectedMaxWin || ticketConfig?.shortTicket ? (
              <KeyValue
                label={T.TOTAL_POSSIBLE_WIN}
                value={`${toFixNumber(possibleWin)} ${currencyCode}`}
                textBold
              />
            ) : null}
          </div>
        </div>
        {ticketConfig?.showQR ? (
          <>
            <QRCode
              size={100}
              style={{ height: "auto", maxWidth: "50%", width: "50%" }}
              value={`${env.httpUrl}/ticketDetails?lang=${selectedLanguageCode}&gameId=${gameId}&betId=${betId}`}
            />
            <p className="scan-ticket-txt">{t(T.SCAN_TO_CHECK_FAIRNESS)}</p>
          </>
        ) : null}
        {/*TODO NEED UI CHANGE*/}
        {ticketConfig?.showComment && ticketConfig?.comment ? (
          <div className="ticket-comment-area">
            <div className="ticket-comment-area-text">
              {ticketConfig?.comment}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
