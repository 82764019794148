import { IconTypes, TicketStatus } from "../enums";
import { T } from "./translation-keys";

export const TicketStatusClassNames = {
  [TicketStatus.Won]: "win",
  [TicketStatus.Lost]: "lost",
  [TicketStatus.Pending]: "pending",
  [TicketStatus.Returned]: "returned",
  [TicketStatus.Canceled]: "canceled",
};

export const CheckTicketStatusClassNames = {
  [TicketStatus.Won]: "state-win",
  [TicketStatus.Lost]: "state-red",
  [TicketStatus.Pending]: "state-yellow",
  [TicketStatus.Returned]: "",
  [TicketStatus.Canceled]: "state-red",
};

export const TicketStatusNames = {
  [TicketStatus.Won]: T.WON,
  [TicketStatus.Lost]: T.LOST,
  [TicketStatus.Pending]: T.PENDING,
  [TicketStatus.Returned]: T.RETURNED,
  [TicketStatus.Canceled]: T.CANCELED,
};
