import {
  GameIds,
  VirtualRaceClassNamesByIds,
} from "../../../../../constants/games";

interface RacingProps {
  gameId: number;
  result?: Array<string>;
}

const Racing: React.FC<RacingProps> = ({ gameId, result }) => {
  const isMarble = gameId === GameIds.VirtualMarbleRacing;

  return (
    <>
      <div
        className={`qrResultMultipleGames ${VirtualRaceClassNamesByIds[gameId]}`}
      >
        {result?.map((item) => (
          <span
            className={`color-${item} ${!isMarble ? "num-box" : ""}`}
            key={item}
          >
            {!isMarble ? item : null}
          </span>
        ))}
        {/*<div className="qrResultMultipleGamesInner">*/}
        {/*  /!*todo you can add class in span below from other games like in pre-recorded 10 harness*!/*/}
        {/*  /!*<span></span>*!/*/}
        {/*  <img src={ball} alt="image" className="multipleGameImg" />*/}
        {/*</div>*/}
        {/*/!*todo and you can add any marble img in this div below or you can change and put span in it*!/*/}
        {/*<div className="qrResultMultipleGamesInner">*/}
        {/*  <img src={ball} alt="image" className="multipleGameImg" />*/}
        {/*</div>*/}
        {/*<div className="qrResultMultipleGamesInner">*/}
        {/*  <img src={ball} alt="image" className="multipleGameImg" />*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default Racing;
