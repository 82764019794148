import React from 'react';

interface ModalContainerProps {

}

export const MODAL_CONTAINER_ID = 'modalContainer';

export const ModalContainer: React.FC<ModalContainerProps> = () => {
  return (
    <div className="modal-container" id={MODAL_CONTAINER_ID} />
  );
};