import { DoubleWheelColorsIndex } from "../enums/fairness-games";

export const DoubleWheelColorsOdds: any = {
  [DoubleWheelColorsIndex.Different]: "x2",
  [DoubleWheelColorsIndex.Blue]: "x4",
  [DoubleWheelColorsIndex.Yellow]: "x6",
  [DoubleWheelColorsIndex.Red]: "x15",
  [DoubleWheelColorsIndex.Purple]: "x25",
  [DoubleWheelColorsIndex.Green]: "x55",
  [DoubleWheelColorsIndex.Wild]: "Wild",
};

export const DoubleWheelColors: any = {
  [DoubleWheelColorsIndex.Blue]: "lightGreen",
  [DoubleWheelColorsIndex.Yellow]: "yellow",
  [DoubleWheelColorsIndex.Red]: "red",
  [DoubleWheelColorsIndex.Purple]: "purple",
  [DoubleWheelColorsIndex.Green]: "green",
  [DoubleWheelColorsIndex.Wild]: "blue",
};
