import React from 'react';

interface HeaderRightProps {
  children: React.ReactNode;
}

export const HeaderRight: React.FC<HeaderRightProps> = ({
  children
}) => {
  return (
    <div className="header-right-side">
      { children }
    </div>
  );
};
