import { LocalStorageHelper } from "../helpers";
import { LocalStorageKeys } from "../constants";
import { LoginResponse } from "../models";

export const getIsAuthDefaultValue = (): boolean => {
  try {
    const access: LoginResponse = LocalStorageHelper.getItem(
      LocalStorageKeys.Access,
    );
    return !!(access && access.expiresIn && access.expiresIn > Date.now());
  } catch (error) {
    return false;
  }
};
