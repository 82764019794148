import { TranslationsStore } from "./translations-store";
import { LiveUpdateStore } from "./live-update-store";
import { ProvidersStore } from "./providers-store";
import { LanguageStore } from "./language-store";
import { TicketsStore } from "./tickets-store";
import { IFrameStore } from "./iframe-store";
import { ModalStore } from "./modal-store";
import { AuthStore } from "./auth-store";
import { UserStore } from "./user-store";
import { ManagerStore } from "./managerStore";
import { PartnerStore } from "./partner-store";

export class RootStore {
  public translationsStore: TranslationsStore;
  public liveUpdateStore: LiveUpdateStore;
  public providersStore: ProvidersStore;
  public languageStore: LanguageStore;
  public ticketsStore: TicketsStore;
  public managerStore: ManagerStore;
  public iFrameStore: IFrameStore;
  public modalStore: ModalStore;
  public authStore: AuthStore;
  public userStore: UserStore;
  public partnerStore: PartnerStore;

  constructor() {
    this.translationsStore = new TranslationsStore(this);
    this.liveUpdateStore = new LiveUpdateStore(this);
    this.providersStore = new ProvidersStore(this);
    this.languageStore = new LanguageStore(this);
    this.ticketsStore = new TicketsStore(this);
    this.managerStore = new ManagerStore(this);
    this.iFrameStore = new IFrameStore(this);
    this.modalStore = new ModalStore(this);
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.partnerStore = new PartnerStore(this);
  }
}

export const rootStore = new RootStore();
