import React, { useCallback } from "react";

import { PaginationSelectorsData } from "../../../../../constants";
import { Pagination } from "../pagination";
import { Dropdown } from "../../dropdown";

interface TableFooterProps {
  count: number;
  currentPage: number;
  selectorValue: number;
  onPaginationChange: (page: number, selectorValue: number) => void;
}

export const TableFooter: React.FC<TableFooterProps> = ({
  count,
  currentPage,
  selectorValue,
  onPaginationChange,
}) => {
  const handleSelectorValueChange = useCallback(
    (value: number) => {
      if (+selectorValue === value) return;
      onPaginationChange(1, +value);
    },
    [selectorValue, onPaginationChange],
  );

  return (
    <div className="main-structure-pagination-wrapper">
      <div className="pagination">
        <div className="pagination-left">
          <Dropdown
            direction="move-up"
            value={selectorValue}
            data={PaginationSelectorsData}
            placeholder={`${selectorValue}`}
            onChange={(value) => handleSelectorValueChange(value as number)}
          />
        </div>
        <Pagination
          count={count}
          currentPage={currentPage}
          selectorValue={selectorValue}
          onPaginationChange={onPaginationChange}
        />
      </div>
    </div>
  );
};
