import React from "react";

import { IconTypes } from "../../../../enums";

interface IconProps {
  type: IconTypes;
  className?: string;
  onClick?: () => void;
}

export const Icon: React.FC<IconProps> = ({
  type,
  onClick,
  className = "",
}) => {
  return <span onClick={onClick} className={`${type} ${className}`} />;
};
