import React from "react";

import { TableHeaderTop } from "./table-header-top";

interface TableHeaderProps {
  title: string;
  actions?: React.ReactNode;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ title, actions }) => {
  return (
    <div className="main-structure-top-wrapper">
      <div className="structure-inner-top-wrapper">
        <TableHeaderTop title={title} actions={actions} />
      </div>
    </div>
  );
};
