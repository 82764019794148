import { BaseApiService } from '../../base-api-service';
import { UserWallet } from '../../../models';
import { ApiVersions } from '../../../enums';
import { env } from '../../../environment';

class UserController extends BaseApiService {

  public async getWallet(): Promise<UserWallet> {
    const url = `${this.url}/api/${ApiVersions.V1}/user/wallet`;
    return await this.http.get(url);
  }

}

export const userController = new UserController({ url: env.httpUrl });
