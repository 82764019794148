import { DropdownDataType, ProviderGames } from "../models";

//TODO refactor
export const gamesDataToDropDownConverter = (
  data: Array<ProviderGames>,
): Array<DropdownDataType> => {
  return data.map((data: any) => ({
    value: data.gameId,
    label: data.gameName,
  }));
};
