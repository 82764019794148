import { useMemo } from "react";

import Header from "../header";
import Status from "../status";
import Footer from "../footer";
import BetItem from "../bet-item";

import {
  BetShopRoundResult,
  BetType,
  TicketDetailsRequest,
} from "../../../../models";

import {
  BetStatusClassNames,
  BetStatusIconNames,
  BetStatusNames,
  BetTypeNames,
  T,
  TICKET_DETAIL_FORMAT,
} from "../../../../constants";

import { useTranslation } from "../../../../hooks";
import { utcToLocal } from "../../../../helpers";
import { BetStatus, TicketStatus } from "../../../../enums";

interface BetSectionProps {
  handleFairnessPopUpClick: () => void;
  openResultSection: (betResult: BetShopRoundResult) => void;
  data: TicketDetailsRequest;
}

const BetSection: React.FC<BetSectionProps> = ({
  data,
  openResultSection,
  handleFairnessPopUpClick,
}) => {
  const t = useTranslation();

  const {
    betId,
    amount,
    betType,
    betStatus,
    createDate,
    betShopBetTicketInfoResult,
  } = data;

  const calculatePossibleWin = useMemo(() => {
    return betShopBetTicketInfoResult?.reduce((acc, currentData) => {
      if (
        currentData.betStatus === BetStatus.Pending ||
        currentData.betStatus === BetStatus.Win
      ) {
        acc += currentData.amount * currentData.odd;
      }
      return acc;
    }, 0);
  }, [betShopBetTicketInfoResult]);

  const calculateTotalBetAmount = useMemo(() => {
    return betShopBetTicketInfoResult?.reduce((acc, currentData) => {
      return (acc += currentData.amount);
    }, 0);
  }, [betShopBetTicketInfoResult]);

  const calculateTotalOdd = useMemo(() => {
    return betShopBetTicketInfoResult?.reduce((acc, currentData) => {
      return (acc *= currentData.odd);
    }, 1);
  }, [betShopBetTicketInfoResult]);

  const calculateTotalWinAmount = useMemo(() => {
    return betShopBetTicketInfoResult?.reduce((acc, currentData) => {
      return (acc += currentData.winAmount);
    }, 0);
  }, [betShopBetTicketInfoResult]);

  return (
    <div className="qrMainHolderBetSection">
      <Header
        handleFairnessPopUpClick={handleFairnessPopUpClick}
        betCount={betShopBetTicketInfoResult?.length}
      />
      <div className="qrTicketBetWrapper">
        <div className="qrTicket-g-wrapper">
          <div className="qrTicketInfoHolder express">
            <div className="qrTicketInfoContainer">
              <span className="qrTicket">{t(BetTypeNames[betType])}</span>
              <div className="qrTicketInfo">
                <span className="qrTicketId">ID: {betId}</span>
                <div className="qrTicketInfoLine" />
                <span className="qrTicketDate">
                  {utcToLocal(createDate, TICKET_DETAIL_FORMAT)}
                </span>
              </div>
            </div>
            <div className="qrTicketStatusContainer">
              <span className="qrTicketStatusTxt">{t(T.TICKET_STATUS)}</span>
              <Status
                status={BetStatusNames[betStatus]}
                icon={BetStatusIconNames[betStatus]}
                className={BetStatusClassNames[betStatus]}
              />
            </div>
          </div>
          <div className="qrTicketScrollablePart-holder">
            <div className="qrTicketScrollablePart">
              {betShopBetTicketInfoResult?.map((data) => (
                <BetItem data={data} openResultSection={openResultSection} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer
        totalOdd={
          betType === BetType.Express ? calculateTotalOdd.toFixed(2) : null
        }
        possibleWin={betStatus === TicketStatus.Pending && calculatePossibleWin}
        totalWinAmount={
          betStatus !== TicketStatus.Pending &&
          calculateTotalWinAmount.toFixed(2)
        }
        totalBetAmount={
          amount ? amount.toFixed(2) : calculateTotalBetAmount.toFixed(2)
        }
        currencyCode={betShopBetTicketInfoResult[0].currencyCode}
      />
    </div>
  );
};

export default BetSection;
