import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";

class ManagerController extends BaseApiService {
  public async transferBalance(
    amount: string | number,
    pinCode: string | number,
  ) {
    let url = `${this.url}/api/${ApiVersions.V1}/wallet/balance-transfer`;
    return await this.http.put(url, { amount, pinCode });
  }

  public async takeBalance(amount: number | string, pinCode: number | string) {
    const url = `${this.url}/api/${ApiVersions.V1}/wallet/balance-take`;
    return await this.http.put(url, { amount, pinCode });
  }
}

export const managerController = new ManagerController({ url: env.httpUrl });
