import { useEffect } from "react";
import JsBarcode from "jsbarcode";

import { useTranslation } from "../../../../../hooks";
import { T } from "../../../../../constants";
import { env } from "../../../../../environment";

interface TicketProps {
  barcode: number;
  showLogo: boolean;
  logo: string;
  onTicketLoad: () => void;
  children: React.ReactNode;
}

export const Ticket: React.FC<TicketProps> = ({
  barcode,
  children,
  onTicketLoad,
  showLogo,
  logo,
}) => {
  const t = useTranslation();

  useEffect(() => {
    if (+barcode) {
      generateBarCode();
    }
  }, [barcode]);

  // useEffect(() => {
  //   onTicketLoad();
  // }, []);

  const generateBarCode = () => {
    JsBarcode("#barcode", `${barcode}`, {
      width: 3,
      height: 72,
      displayValue: false,
    });
  };

  return (
    <div className="ticket-wr">
      {showLogo ? (
        <img
          className="provider-logo"
          src={`${env.mediaUrl}/gravity-media/${logo}`}
          alt="Pascal Gaming"
          onLoad={onTicketLoad}
        />
      ) : null}

      {!+barcode ? (
        <h1 style={{ marginTop: "4mm", fontSize: "20px" }}>{t(T.COPY)}</h1>
      ) : null}

      <div className="ticket-content">{children}</div>

      {+barcode ? (
        <div className="barcode-wr">
          <img id="barcode" alt="barcode-err" />
          <span>{barcode}</span>
        </div>
      ) : null}
    </div>
  );
};
