import {
  BetTypeNames,
  CheckTicketStatusClassNames,
  DATE_FORMAT_LONG,
  T,
  TicketStatusNames,
} from "../../../../../constants";
import TicketResultKeyValue from "./ticket-result-key-value";
import { useTranslation } from "../../../../../hooks";
import { utcToLocal } from "../../../../../helpers";
import { Ticket } from "../../../../../models";
import { Currency } from "../../../shared";
import { TicketStatus } from "../../../../../enums";

interface TicketResultHeaderProps {
  ticket: Ticket;
  isExpress: boolean;
  returnedManually: boolean;
  isPinRequiredForPayout: boolean;
}

const TicketResultHeader: React.FC<TicketResultHeaderProps> = ({
  ticket,
  isExpress,
  returnedManually,
  isPinRequiredForPayout,
}) => {
  const t = useTranslation();
  const { ticketId, status, date, barcode, amount, odds, winAmount, betType } =
    ticket;

  return (
    <div
      className={`date-space ${CheckTicketStatusClassNames[returnedManually ? TicketStatus.Canceled : status]}`}
    >
      <div className="date-block ">
        <TicketResultKeyValue
          label={T.DATE}
          value={utcToLocal(date, DATE_FORMAT_LONG)}
        >
          <span className="result-pp">
            {t(
              TicketStatusNames[
                returnedManually ? TicketStatus.Canceled : status
              ],
            )}
          </span>
        </TicketResultKeyValue>

        {!isPinRequiredForPayout ? (
          <TicketResultKeyValue label={T.BARCODE} value={barcode} />
        ) : null}

        <TicketResultKeyValue label={T.TICKET_ID} value={ticketId} />

        <TicketResultKeyValue
          label={T.BET_TYPE}
          value={t(BetTypeNames[betType])}
        />

        {isExpress && (
          <>
            <TicketResultKeyValue
              label={T.BET_AMOUNT}
              value={<Currency value={amount} />}
            />
            <TicketResultKeyValue label={T.TOTAL_ODDS} value={`x${odds}`} />
            <TicketResultKeyValue
              label={T.WIN}
              value={<Currency value={winAmount} />}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TicketResultHeader;
