import { makeObservable, observable, action } from "mobx";

import { Translations, GetTranslationsRequest } from "../models";
import { HttpResponse, translationsController } from "../api";
import { getCurrentLanguageCode } from "../helpers";
import { RootStore } from ".";

export class TranslationsStore {
  private rootStore?: RootStore;

  public translations = new HttpResponse<Translations>({});

  public selectedLanguageCode = getCurrentLanguageCode();

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      translations: observable,
      selectedLanguageCode: observable,
      setTranslations: action.bound,
      setSelectedLanguageCode: action.bound,
    });
  }

  public setTranslations(value: HttpResponse<Translations>): void {
    this.translations = value;
  }

  public setSelectedLanguageCode(value: string): void {
    this.selectedLanguageCode = value;
  }

  public async getTranslations(params: GetTranslationsRequest): Promise<void> {
    this.setTranslations(this.translations.fetching());
    const data = await translationsController.getTranslations(params);
    this.setTranslations(this.translations.fetched(data));
  }

  public translate(alias: string): string {
    return this.translations.data[alias] || alias;
  }
}
