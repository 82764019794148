import React, { memo, useCallback, useMemo } from "react";

import { KeyValuePair, TableColumn } from "../../../../../../models";
import { TableCellValue } from "./table-row/table-cell-value";
import { TableCell } from "./table-row/table-cell";
import TableRow from "./table-row";

interface TableBodyProps {
  rowKey?: string;
  isDrawPrintIcon?: boolean;
  rows: Array<KeyValuePair>;
  columns: Array<TableColumn>;
  onRowClick: (row: any, index: number) => void;
}

const TableBody: React.FC<TableBodyProps> = ({
  rows,
  columns,
  onRowClick,
  rowKey = "id",
  isDrawPrintIcon,
}) => {
  const drawColumn = useCallback(
    (col: TableColumn, row: KeyValuePair, index: number) => {
      if (col.hideColumn) return;

      return (
        <TableCell
          key={col.dataKey}
          children={
            col.colRenderer ? (
              col.colRenderer(row[col.dataKey], row, index)
            ) : (
              <TableCellValue
                value={
                  col.formatter?.(row[col.dataKey]) ||
                  `${row[col.dataKey] ?? "-"}`
                }
              />
            )
          }
        />
      );
    },
    [columns],
  );

  return (
    <div className="main-table-body">
      <div className="scroll-holder">
        <div className="scrollable-container">
          {rows.length ? (
            rows.map((row, index) => (
              <TableRow
                key={row[rowKey]}
                barCode={row.barcode}
                ticketId={row.ticketId}
                onClick={() => onRowClick(row, index)}
                status={row?.status}
                isDrawPrintIcon={isDrawPrintIcon}
                paidAmount={row?.paidAmount}
              >
                {columns.map((col) => drawColumn(col, row, index))}
              </TableRow>
            ))
          ) : (
            <div className="empty-result">
              <span className="empty-result-logo" />
              <p className="empty-result-text">No Results</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TableBody);
