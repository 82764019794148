import { useMemo } from "react";
import { GameIds, MASTS } from "../../../../../constants/games";
import { getNextBeforeNumbersDataRoulette } from "../../../../../helpers/next-before-numbers-data-roulette";

interface SpinToWinProps {
  gameId: number;
  result: string;
}

const Roulette: React.FC<SpinToWinProps> = ({ gameId, result }) => {
  const { number, mastNumber } = JSON.parse(result);

  const resultData = useMemo((): any => {
    return getNextBeforeNumbersDataRoulette(number);
  }, [result]);

  if (!result) return null;

  return (
    <>
      {gameId === GameIds.VirtualSpinToWinDeluxe ? (
        <div
          className={`nonStopRouletteAndSpinHolderLeft ${MASTS[mastNumber]}`}
        />
      ) : null}

      <div className="nonStopRouletteAndSpinHolderMiddle">
        <div
          className={`rouletteAndSpinImgHolder leftCard ${resultData.beforeNumberColor}`}
        >
          <span className="rouletteImgTxt leftTxt">
            {resultData?.beforeNumber}
          </span>
        </div>
        <div
          className={`rouletteAndSpinImgHolder middleCard ${resultData.winNumberColor} active`}
        >
          <span className="rouletteImgTxt middleTxt">{number}</span>
        </div>
        <div
          className={`rouletteAndSpinImgHolder rightCard ${resultData.nextNumberColor}`}
        >
          <span className="rouletteImgTxt rightTxt">
            {resultData.nextNumber}
          </span>
        </div>
      </div>
      {gameId === GameIds.VirtualSpinToWin ||
      gameId === GameIds.VirtualSpinToWinDeluxe ? (
        <span className="nonStopRouletteAndSpinHolderRight">
          {resultData.sector}
        </span>
      ) : null}
    </>
  );
};

export default Roulette;
