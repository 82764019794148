import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';

export class BaseLiveConnection {
  public connection: HubConnection | null = null;

  private connectionStartPromise: Promise<void> | null = null;
  private connectionStopPromise: Promise<void> | null = null;

  public url: string;

  constructor(url: string) {
    this.url = url;
  }

  private async waitStarted() {
    if (this.connectionStartPromise instanceof Promise) {
      await this.connectionStartPromise;
      this.connectionStartPromise = null;
    }
  }
  private async waitStop() {
    if (this.connectionStopPromise instanceof Promise) {
      await this.connectionStopPromise;
      this.connectionStopPromise = null;
    }
  }

  async init(reconnectTimes?: Array<number>): Promise<HubConnection> {
    await this.waitStop();
    await this.waitStarted();
    if (this.connection?.state !== HubConnectionState.Connected) {
      this.connection = this.createConnection(reconnectTimes);
      this.connectionStartPromise = this.connection.start();
      await this.waitStarted();
    }
    return this.connection;
  }

  public createConnection(reconnectTimes: Array<number> = [300, 600]): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(this.url)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect(reconnectTimes)
      .build();
  }

  public async invoke<T = any>(methodName: string, ...args: any[]): Promise<T | void> {
    await this.waitStarted();
    if (this.connection?.state === HubConnectionState.Connected) return this.connection.invoke<T>(methodName, ...args);
  }

  public on(methodName: string, handler: (...args: any[]) => void): this {
    if (this.connection?.state === HubConnectionState.Connected) this.connection.on(methodName, handler);
    return this;
  }

  public onReconnecting(callback?: (error?: Error) => void): void {
    if (callback && this.connection) {
      this.connection.onreconnecting(callback);
    }
  }

  public onClose(callback?: (error?: Error) => void): void {
    if (callback && this.connection && this.connection.state === HubConnectionState.Connected) {
      this.connection.onclose(callback);
    }
  }

  public onReconnected(callback?: (connectionId?: string) => void) {
    if (callback && this.connection) {
      this.connection.onreconnected(callback);
    }
  }

  public async stop(): Promise<void> {
    await this.waitStarted();
    await this.waitStop();
    if (this.connection?.state === HubConnectionState.Connected) {
      this.connectionStopPromise = this.connection.stop().then(() => {
        this.connectionStartPromise = null;
      });
      await this.waitStop();
    }
  }
}
