import React, { useMemo } from "react";

import {
  BetStatusClassNames,
  BetStatusNames,
  T,
} from "../../../../../../constants";
import { useTranslation } from "../../../../../../hooks";
import { BetStatus } from "../../../../../../enums";
import { Currency } from "../../../../shared";

interface MarketListPropsItem {
  odds: number;
  status: BetStatus;
  winAmount: number;
  betAmount: number;
  ticketStatus: number;
  isExpress: boolean;
  marketName: string;
  gameName: string;
  handicap?: number;
  roundId: number;
  // result?: any;
  selectionName?: string;
}

export const MarketListItem: React.FC<MarketListPropsItem> = ({
  odds,
  status,
  betAmount,
  winAmount,
  isExpress,
  marketName,
  ticketStatus,
  selectionName,
  handicap,
  roundId,
  gameName,
  // result
}) => {
  const t = useTranslation();

  const showBetResult = useMemo(() => {
    return status === BetStatus.Win || status === BetStatus.Lose;
  }, [status]);

  return (
    <div className="bet-row">
      <div className="bet-row-wrp">
        <div className="bet-row-inner"></div>

        <div className="bet-row-result">
          <span className={`result-pp ${BetStatusClassNames[status]}`}>
            {ticketStatus !== BetStatus.Canceled
              ? t(BetStatusNames[status])
              : null}
          </span>
        </div>
      </div>

      <div className="bet-row-wrp">
        <div className="bet-row-result">
          <span className="stake-cont">{`${t(T.GAME_NAME)}:`}</span>
          <span className="position-bet">{gameName}</span>
        </div>
      </div>

      <div className="bet-row-wrp">
        {selectionName || marketName ? (
          <div className="bet-row-inner">
            <span className="stake-cont">{`${t(T.MARKET_NAME)}:`}</span>
            <span className="bet-info">{`${t(marketName)} ${t(selectionName || "")}`}</span>
            <span className="bet-info">{handicap}</span>
          </div>
        ) : null}

        {/*{*/}
        {/*	result ? <div className="bet-row-inner">*/}
        {/*		   <span className="bet-info">*/}
        {/*         {`${t(result)}`}*/}
        {/*      </span>*/}
        {/*	</div> : null*/}
        {/*}*/}

        <div className="bet-row-result">
          <span className="cof-bet">x{odds}</span>
        </div>
      </div>

      <div className="bet-row-wrp">
        <div className="bet-row-result">
          <span className="stake-cont">{`${t(T.ROUND_ID)}:`}</span>
          <span className="position-bet">{roundId}</span>
        </div>
      </div>

      <div className="bet-row-wrp">
        {!isExpress && (
          <div className="bet-row-inner">
            <span className="stake-cont">{`${t(T.TICKET_STAKE)}:`}</span>
            <span className="position-bet">
              <Currency value={betAmount} />
            </span>
          </div>
        )}

        {showBetResult && !isExpress && (
          <div className="bet-row-result">
            <span className="stake-cont">{`${t(T.WIN)}:`}</span>
            <span className="position-bet-cont">
              <Currency value={winAmount} />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
