import React, { MutableRefObject, useCallback, useRef } from "react";

import { DropdownDataType, DropdownValue } from "../../../../models";
import { DropdownListItem } from "./dropdown-list-item";
import { useOutsideClick } from "../../../../hooks";
import { DirectionValue } from "./index";

interface DropdownListProps {
  onClose: () => void;
  value: DropdownValue;
  direction?: DirectionValue;
  data: Array<DropdownDataType>;
  onClick: (value: DropdownValue) => void;
  openerRef: MutableRefObject<HTMLDivElement | null>;
}

export const DropdownList: React.FC<DropdownListProps> = ({
  data,
  value,
  onClick,
  onClose,
  openerRef,
  direction,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(onClose, dropdownRef, openerRef);

  const handleClick = useCallback(
    (itemValue: DropdownValue) => {
      onClick(itemValue);
      onClose();
    },
    [onClose, onClick],
  );

  return (
    <div ref={dropdownRef} className={`top-tabs-muv-cont-wrp ${direction}`}>
      <div className="wrp-muv-cont">
        <div className="drop-down-cont">
          <div className="scroll-holder">
            <div className="scrollable-container static-scroll">
              {data.map(({ value: itemValue, label }) => (
                <DropdownListItem
                  label={label}
                  key={`${itemValue}`}
                  isActive={value === itemValue}
                  onClick={() => handleClick(itemValue)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
