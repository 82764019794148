import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { MODAL_CONTAINER_ID } from '../../../containers';
import { useOutsideClick } from '../../../../hooks';
import { ModalStructure } from './modal-structure';

interface ModalProps {
  title?: string;
  width?: number;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
   width,
   onClose,
   children,
   title = '',
}) => {

  const modalRef = useRef<null | HTMLDivElement>(null);

  const [ modalContainer, setModalContainer ] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setModalContainer(document.getElementById(MODAL_CONTAINER_ID));
  }, []);

  // useOutsideClick(onClose, modalRef);

  if (!modalContainer) return null;

  return (
    createPortal(
      <ModalStructure
        width={width}
        title={title}
        onClose={onClose}
        children={children}
        modalRef={modalRef}
      />,
      modalContainer
    )
  );
};
