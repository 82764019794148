import React, { useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { KeyValuePair, LanguageModel } from '../../../../../../models';
import { LocalStorageKeys } from '../../../../../../constants';
import { LocalStorageHelper } from '../../../../../../helpers';
import { SelectedLanguage } from './selected-language';
import { useStore } from '../../../../../../hooks';
import { LanguageList } from './language-list';

interface LanguagesProps {

}

export const Languages: React.FC<LanguagesProps> = observer(() => {

  const { translationsStore, providersStore, languageStore, authStore } = useStore();
  const { selectedLanguageCode, setSelectedLanguageCode } = translationsStore;
  const { providers: { data: { partnerId } } } = providersStore;
  const { languages: { data: languages } } = languageStore;

  const selectedLanguageRef = useRef<HTMLDivElement | null>(null);

  const [ open, setOpen ] = useState(false);

  const handleSelect = useCallback((code: string) => {
    setOpen(false);
    if (selectedLanguageCode === code) return;
    setSelectedLanguageCode(code);
    authStore.resetProviderToken();
    LocalStorageHelper.setItem(LocalStorageKeys.Language, code);
    translationsStore.getTranslations({ code, partnerId });
  }, [selectedLanguageCode, partnerId]);

  const LANGUAGES_MAP = useMemo(() => {
    return languages.reduce<KeyValuePair<LanguageModel>>((acc, curr) => {
      acc[curr.uid] = curr;
      return acc;
    }, {});
  }, [languages]);

  const selectedLanguage = useMemo(() => {
    return LANGUAGES_MAP[selectedLanguageCode];
  }, [languages, selectedLanguageCode]);

  return (
    <div className={`select-country-wrapper ${open ? 'active' : ''}`}>
      <SelectedLanguage
        ref={selectedLanguageRef}
        flag={selectedLanguage?.flag}
        label={selectedLanguage?.code}
        onClick={() => setOpen((prev) => !prev)}
      />
      <LanguageList
        languages={languages}
        onSelect={handleSelect}
        selected={selectedLanguageCode}
        openerRef={selectedLanguageRef}
        onOutsideClick={() => setOpen(false)}
      />
    </div>
  );
});
