import { NavLink } from "react-router-dom";
import { NAVIGATION_ITEMS, RoutePaths } from "../../../../../../constants";
import { useTranslation } from "../../../../../../hooks";
import { Icon } from "../../../../shared";
import { IS_UNIVERSAL_PARTNER } from "../../../../../../constants/host-name";

interface NavigationProps {}

export const Navigation: React.FC<NavigationProps> = () => {
  const t = useTranslation();

  return (
    <>
      {!IS_UNIVERSAL_PARTNER ? (
        <NavLink to={RoutePaths.Game} className="header-logo-container">
          <span className="gravity-logo-in-header" />
        </NavLink>
      ) : null}

      {NAVIGATION_ITEMS.map(({ path, icon, label }) => (
        <NavLink
          to={path}
          key={path}
          className={({ isActive }) =>
            `header-left-side-column ${isActive ? "active" : ""}`
          }
        >
          <p className="header-icon-container">
            <Icon type={icon} />
          </p>
          <span className="header-link-title">{t(label)}</span>
        </NavLink>
      ))}
    </>
  );
};
