import { getCurrentLanguageCode } from "../helpers";
import { rootStore } from "../store";

class AppService {
  public async init(): Promise<void> {
    const {
      translationsStore,
      userStore,
      providersStore,
      liveUpdateStore,
      languageStore,
      partnerStore,
    } = rootStore;
    liveUpdateStore.init();
    const providers = await providersStore.getProviders();
    const lan = await languageStore.getLanguages();
    const { partnerId } = providers;

    translationsStore.getTranslations({
      code: getCurrentLanguageCode() || lan,
      partnerId,
    });

    await Promise.all([
      userStore.getWallet(),
      partnerStore.getPartnerBusiness(),
    ]);
  }
}

export const appService = new AppService();
