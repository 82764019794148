export class JSONHelper {
  static stringify(key: any): string {
    try {
      return JSON.stringify(key);
    } catch (err) {
      throw new Error("JSON Stringify error.");
    }
  }

  static parse(key: string): any {
    try {
      return JSON.parse(key);
    } catch (err) {
      throw new Error("JSON Parse error.");
    }
  }
}
