import React from "react";

import { useTranslation } from "../../../../../hooks";
import { MONTHS } from "../../../../../constants";

interface NavigationProps {
  year: number;
  month: number;
  onYearNavClick: (step: -1 | 1) => void;
  onMonthNavClick: (step: -1 | 1) => void;
}

export const Navigation: React.FC<NavigationProps> = ({
  year,
  month,
  onYearNavClick,
  onMonthNavClick,
}) => {
  const t = useTranslation();

  return (
    <div className="mount-block">
      <span
        className="icon-left-all-stroke"
        onClick={() => onYearNavClick(-1)}
      />
      <span className="icon-left-filed" onClick={() => onMonthNavClick(-1)} />
      <p className="mount-text">
        {year} {t(MONTHS[month])}
      </p>
      <span className="icon-right-filed" onClick={() => onMonthNavClick(1)} />
      <span
        className="icon-right-all-stroke"
        onClick={() => onYearNavClick(1)}
      />
    </div>
  );
};
