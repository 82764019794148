import React from "react";
import { useTranslation } from "../../../../../../hooks";

interface KeyValueProps {
  label: string;
  textBold?: boolean;
  value: string | number;
}

export const KeyValue: React.FC<KeyValueProps> = ({
  label,
  value,
  textBold,
}) => {
  const t = useTranslation();

  return (
    <div className={`ticket-key-value ${textBold ? "textBold" : ""}`}>
      <div className="ticket-key">{t(label)}</div>
      <div className="ticket-value">
        {typeof value === "string" ? t(value) : value}
      </div>
    </div>
  );
};
