import React, { ReactNode } from "react";

interface GameResultSectionProps {
  children: ReactNode;
  className?: string;
}

const GameResultSection: React.FC<GameResultSectionProps> = ({
  children,
  className,
}) => {
  return (
    <div className="qrResultGameSection">
      <div className={`qrResultGameMiddle ${className || "withFlexColumn"}`}>
        {children}
      </div>
    </div>
  );
};

export default GameResultSection;
